let positions = [
	{
		positionIndex: 0,
		id: 'planet-1',
		positionsArray: [
			{ x: -459.6266658713869, y: 144.62721217947134 },
			{ x: -460.97011415611394, y: 144.024682384115 },
			{ x: -462.3079456654734, y: 143.42039769345524 },
			{ x: -463.64014409841036, y: 142.81436547051032 },
			{ x: -464.96669322250744, y: 142.2065930995916 },
			{ x: -466.28757687418215, y: 141.59708798621358 },
			{ x: -467.602778958884, y: 140.98585755700384 },
			{ x: -468.9122834512907, y: 140.37290925961216 },
			{ x: -470.21607439550326, y: 139.7582505626201 },
			{ x: -471.51413590524044, y: 139.14188895545 },
			{ x: -472.8064521640324, y: 138.52383194827348 },
			{ x: -474.09300742541336, y: 137.90408707192012 },
			{ x: -475.3737860131136, y: 137.2826618777856 },
			{ x: -476.64877232125, y: 136.65956393773993 },
			{ x: -477.9179508145168, y: 136.0348008440348 },
			{ x: -479.1813060283747, y: 135.40838020921137 },
			{ x: -480.438822569239, y: 134.78030966600733 },
			{ x: -481.6904851146675, y: 134.15059686726414 },
			{ x: -482.9362784135472, y: 133.51924948583346 },
			{ x: -484.1761872862799, y: 132.88627521448385 },
			{ x: -485.41019662496706, y: 132.25168176580718 },
			{ x: -486.63829139359456, y: 131.61547687212425 },
			{ x: -487.86045662821505, y: 130.9776682853909 },
			{ x: -489.07667743713097, y: 130.33826377710358 },
			{ x: -490.2869390010755, y: 129.69727113820434 },
			{ x: -491.49122657339336, y: 129.05469817898629 },
			{ x: -492.6895254802207, y: 128.41055272899806 },
			{ x: -493.8818211206633, y: 127.76484263694877 },
			{ x: -495.0680989669752, y: 127.11757577061209 },
			{ x: -496.2483445647352, y: 126.46876001673046 },
			{ x: -497.422543533023, y: 125.81840328091916 },
			{ x: -498.5906815645949, y: 125.16651348756976 },
			{ x: -499.7527444260575, y: 124.51309857975365 },
			{ x: -500.90871795804173, y: 123.85816651912526 },
			{ x: -502.0585880753747, y: 123.2017252858251 },
			{ x: -503.2023407672522, y: 122.54378287838237 },
			{ x: -504.3399620974083, y: 121.88434731361774 },
			{ x: -505.47143820428624, y: 121.22342662654556 },
			{ x: -506.5967553012067, y: 120.56102887027562 },
			{ x: -507.715899676536, y: 119.89716211591569 },
			{ x: -508.82885769385314, y: 119.2318344524726 },
			{ x: -509.93561579211587, y: 118.56505398675398 },
			{ x: -511.03616048582603, y: 117.89682884326938 },
			{ x: -512.1304783651938, y: 117.2271671641313 },
			{ x: -513.2185560963013, y: 116.55607710895593 },
			{ x: -514.3003804212647, y: 115.88356685476388 },
			{ x: -515.3759381583959, y: 115.20964459588033 },
			{ x: -516.4452162023633, y: 114.53431854383537 },
			{ x: -517.5082015243516, y: 113.85759692726367 },
			{ x: -518.5648811722199, y: 113.17948799180479 },
			{ x: -519.6152422706602, y: 112.50000000000192 },
			{ x: -520.659272021354, y: 111.81914123120193 },
			{ x: -521.6969577031283, y: 111.136919981454 },
			{ x: -522.7282866721106, y: 110.45334456340876 },
			{ x: -523.7532463618827, y: 109.76842330621696 },
			{ x: -524.7718242836343, y: 109.08216455542802 },
			{ x: -525.7840080263148, y: 108.39457667288818 },
			{ x: -526.7897852567851, y: 107.70566803663878 },
			{ x: -527.7891437199665, y: 107.01544704081425 },
			{ x: -528.7820712389921, y: 106.32392209553943 },
			{ x: -529.7685557153528, y: 105.63110162682774 },
			{ x: -530.748585129047, y: 104.93699407647787 },
			{ x: -531.7221475387255, y: 104.2416079019713 },
			{ x: -532.6892310818374, y: 103.54495157636906 },
			{ x: -533.649823974775, y: 102.84703358820873 },
			{ x: -534.6039145130172, y: 102.14786244140055 },
			{ x: -535.5514910712718, y: 101.44744665512454 },
			{ x: -536.4925421036173, y: 100.74579476372585 },
			{ x: -537.4270561436441, y: 100.04291531661138 },
			{ x: -538.3550218045934, y: 99.33881687814544 },
			{ x: -539.2764277794965, y: 98.63350802754523 },
			{ x: -540.1912628413124, y: 97.92699735877653 },
			{ x: -541.0995158430645, y: 97.21929348044893 },
			{ x: -542.0011757179768, y: 96.51040501571076 },
			{ x: -542.896231479608, y: 95.80034060214436 },
			{ x: -543.7846722219862, y: 95.08910889166042 },
			{ x: -544.6664871197414, y: 94.37671855039292 },
			{ x: -545.5416654282374, y: 93.66317825859339 },
			{ x: -546.4101964837024, y: 92.94849671052509 },
			{ x: -547.2720697033599, y: 92.23268261435713 },
			{ x: -548.1272745855566, y: 91.51574469205833 },
			{ x: -548.9758007098909, y: 90.7976916792911 },
			{ x: -549.8176377373397, y: 90.07853232530479 },
			{ x: -550.6527754103848, y: 89.358275392829 },
			{ x: -551.4812035531368, y: 88.6369296579674 },
			{ x: -552.3029120714602, y: 87.91450391009005 },
			{ x: -553.1178909530964, y: 87.19100695172668 },
			{ x: -553.9261302677846, y: 86.46644759845938 },
			{ x: -554.727620167384, y: 85.74083467881523 },
			{ x: -555.5223508859929, y: 85.01417703415873 },
			{ x: -556.3103127400684, y: 84.2864835185839 },
			{ x: -557.0914961285441, y: 83.5577629988066 },
			{ x: -557.8658915329468, y: 82.82802435405637 },
			{ x: -558.633489517513, y: 82.09727647596824 },
			{ x: -559.3942807293033, y: 81.36552826847465 },
			{ x: -560.148255898317, y: 80.63278864769653 },
			{ x: -560.8954058376046, y: 79.89906654183487 },
			{ x: -561.6357214433799, y: 79.16437089106196 },
			{ x: -562.3691936951308, y: 78.42871064741254 },
			{ x: -563.0958136557294, y: 77.69209477467444 },
			{ x: -563.8155724715409, y: 76.95453224827968 },
			{ x: -564.5284613725312, y: 76.21603205519479 },
			{ x: -565.234471672374, y: 75.47660319381168 },
			{ x: -565.9335947685565, y: 74.73625467383758 },
			{ x: -566.6258221424845, y: 73.99499551618563 },
			{ x: -567.311145359586, y: 73.25283475286473 },
			{ x: -567.9895560694138, y: 72.50978142686945 },
			{ x: -568.6610460057474, y: 71.76584459207021 },
			{ x: -569.325606986694, y: 71.02103331310258 },
			{ x: -569.9832309147874, y: 70.2753566652569 },
			{ x: -570.6339097770881, y: 69.5288237343678 },
			{ x: -571.2776356452792, y: 68.78144361670346 },
			{ x: -571.9144006757642, y: 68.03322541885471 },
			{ x: -572.5441971097621, y: 67.2841782576243 },
			{ x: -573.1670172734022, y: 66.53431125991538 },
			{ x: -573.7828535778174, y: 65.78363356262061 },
			{ x: -574.3916985192365, y: 65.032154312511 },
			{ x: -574.993544679076, y: 64.27988266612407 },
			{ x: -575.5883847240306, y: 63.5268277896522 },
			{ x: -576.1762114061619, y: 62.77299885883165 },
			{ x: -576.7570175629874, y: 62.01840505882992 },
			{ x: -577.3307961175673, y: 61.26305558413443 },
			{ x: -577.897540078591, y: 60.506959638440016 },
			{ x: -578.4572425404622, y: 59.750126434537236 },
			{ x: -579.009896683383, y: 58.99256519419995 },
			{ x: -579.5554957734371, y: 58.23428514807254 },
			{ x: -580.0940331626717, y: 57.475295535558345 },
			{ x: -580.6255022891789, y: 56.715605604706134 },
			{ x: -581.1498966771749, y: 55.95522461209793 },
			{ x: -581.6672099370795, y: 55.19416182273621 },
			{ x: -582.1774357655942, y: 54.432426509930856 },
			{ x: -582.680567945778, y: 53.67002795518638 },
			{ x: -583.1766003471241, y: 52.90697544808841 },
			{ x: -583.6655269256343, y: 52.14327828619114 },
			{ x: -584.1473417238926, y: 51.37894577490328 },
			{ x: -584.6220388711375, y: 50.613987227375446 },
			{ x: -585.0896125833343, y: 49.84841196438603 },
			{ x: -585.5500571632449, y: 49.08222931422796 },
			{ x: -586.0033670004972, y: 48.315448612595205 },
			{ x: -586.4495365716541, y: 47.54807920246844 },
			{ x: -586.88856044028, y: 46.7801304340019 },
			{ x: -587.3204332570076, y: 46.011611664408925 },
			{ x: -587.745149759603, y: 45.242532257848275 },
			{ x: -588.1627047730298, y: 44.47290158530964 },
			{ x: -588.5730932095116, y: 43.70272902450003 },
			{ x: -588.976310068595, y: 42.93202395972899 },
			{ x: -589.37235043721, y: 42.16079578179439 },
			{ x: -589.7612094897293, y: 41.38905388786856 },
			{ x: -590.1428824880284, y: 40.61680768138254 },
			{ x: -590.517364781542, y: 39.84406657191285 },
			{ x: -590.8846518073218, y: 39.07083997506586 },
			{ x: -591.244739090091, y: 38.297137312364015 },
			{ x: -591.5976222423, y: 37.5229680111297 },
			{ x: -591.9432969641787, y: 36.74834150437173 },
			{ x: -592.2817590437899, y: 35.97326723066949 },
			{ x: -592.6130043570798, y: 35.197754634058626 },
			{ x: -592.9370288679289, y: 34.42181316391537 },
			{ x: -593.2538286282007, y: 33.64545227484147 },
			{ x: -593.5633997777903, y: 32.8686814265495 },
			{ x: -593.8657385446706, y: 32.091510083747174 },
			{ x: -594.1608412449395, y: 31.3139477160218 },
			{ x: -594.4487042828636, y: 30.53600379772564 },
			{ x: -594.7293241509225, y: 29.757687807859742 },
			{ x: -595.0026974298518, y: 28.97900922995876 },
			{ x: -595.2688207886843, y: 28.199977551975653 },
			{ x: -595.5276909847909, y: 27.420602266165407 },
			{ x: -595.7793048639198, y: 26.640892868970127 },
			{ x: -596.0236593602356, y: 25.86085886090247 },
			{ x: -596.2607514963556, y: 25.080509746430554 },
			{ x: -596.4905783833871, y: 24.299855033862762 },
			{ x: -596.7131372209619, y: 23.51890423522953 },
			{ x: -596.9284252972709, y: 22.73766686617053 },
			{ x: -597.1364399890967, y: 21.956152445816205 },
			{ x: -597.3371787618461, y: 21.17437049667346 },
			{ x: -597.5306391695804, y: 20.392330544508308 },
			{ x: -597.7168188550455, y: 19.61004211823078 },
			{ x: -597.8957155497009, y: 18.82751474977886 },
			{ x: -598.0673270737466, y: 18.044757974001133 },
			{ x: -598.2316513361505, y: 17.261781328541954 },
			{ x: -598.3886863346734, y: 16.47859435372505 },
			{ x: -598.538430155893, y: 15.695206592436211 },
			{ x: -598.6808809752285, y: 14.911627590007981 },
			{ x: -598.8160370569616, y: 14.127866894103525 },
			{ x: -598.9438967542578, y: 13.343934054599597 },
			{ x: -599.0644585091874, y: 12.559838623470142 },
			{ x: -599.1777208527432, y: 11.775590154670526 },
			{ x: -599.2836824048593, y: 10.991198204021545 },
			{ x: -599.3823418744278, y: 10.206672329090532 },
			{ x: -599.4736980593141, y: 9.422022089078226 },
			{ x: -599.5577498463718, y: 8.637257044700602 },
			{ x: -599.6344962114566, y: 7.85238675807103 },
			{ x: -599.7039362194382, y: 7.067420792587222 },
			{ x: -599.766069024212, y: 6.282368712811001 },
			{ x: -599.8208938687094, y: 5.497240084355535 },
			{ x: -599.8684100849068, y: 4.7120444737635765 },
			{ x: -599.9086170938343, y: 3.926791448396917 },
			{ x: -599.9415144055822, y: 3.141490576316258 },
			{ x: -599.9671016193071, y: 2.356151426164765 },
			{ x: -599.9853784232367, y: 1.570783567050075 },
			{ x: -599.9963445946742, y: 0.7853965684314527 },
			{ x: -600, y: 0 },
			{ x: -599.9963445946743, y: -0.7853965684171401 },
			{ x: -599.9853784232371, y: -1.5707835670321841 },
			{ x: -599.9671016193075, y: -2.356151426145681 },
			{ x: -599.9415144055829, y: -3.141490576298367 },
			{ x: -599.9086170938351, y: -3.926791448379741 },
			{ x: -599.8684100849079, y: -4.712044473745685 },
			{ x: -599.8208938687104, y: -5.497240084338154 },
			{ x: -599.7660690242134, y: -6.282368712793107 },
			{ x: -599.7039362194397, y: -7.0674207925697266 },
			{ x: -599.6344962114583, y: -7.852386758053137 },
			{ x: -599.5577498463737, y: -8.637257044682382 },
			{ x: -599.4736980593161, y: -9.422022089060329 },
			{ x: -599.3823418744299, y: -10.206672329072635 },
			{ x: -599.2836824048617, y: -10.991198204003647 },
			{ x: -599.1777208527457, y: -11.775590154653106 },
			{ x: -599.06445850919, y: -12.559838623452466 },
			{ x: -598.9438967542607, y: -13.343934054581485 },
			{ x: -598.8160370569645, y: -14.127866894085622 },
			{ x: -598.6808809752317, y: -14.911627589990456 },
			{ x: -598.5384301558964, y: -15.695206592418307 },
			{ x: -598.3886863346768, y: -16.478594353707315 },
			{ x: -598.2316513361542, y: -17.26178132852437 },
			{ x: -598.0673270737504, y: -18.04475797398338 },
			{ x: -597.8957155497048, y: -18.827514749761097 },
			{ x: -597.7168188550496, y: -19.610042118213013 },
			{ x: -597.5306391695847, y: -20.392330544490395 },
			{ x: -597.3371787618505, y: -21.174370496655676 },
			{ x: -597.1364399891014, y: -21.956152445798413 },
			{ x: -596.9284252972757, y: -22.737666866152857 },
			{ x: -596.7131372209668, y: -23.518904235211966 },
			{ x: -596.4905783833922, y: -24.29985503384507 },
			{ x: -596.2607514963609, y: -25.08050974641296 },
			{ x: -596.023659360241, y: -25.86085886088454 },
			{ x: -595.7793048639255, y: -26.6408928689523 },
			{ x: -595.5276909847967, y: -27.42060226614768 },
			{ x: -595.2688207886902, y: -28.19997755195811 },
			{ x: -595.0026974298579, y: -28.97900922994111 },
			{ x: -594.7293241509288, y: -29.75768780784199 },
			{ x: -594.44870428287, y: -30.536003797707973 },
			{ x: -594.1608412449461, y: -31.31394771600412 },
			{ x: -593.8657385446775, y: -32.09151008372949 },
			{ x: -593.5633997777971, y: -32.86868142653198 },
			{ x: -593.2538286282078, y: -33.64545227482402 },
			{ x: -592.9370288679361, y: -34.42181316389782 },
			{ x: -592.6130043570872, y: -35.19775463404114 },
			{ x: -592.2817590437975, y: -35.97326723065191 },
			{ x: -591.9432969641865, y: -36.748341504354066 },
			{ x: -591.5976222423078, y: -37.52296801111217 },
			{ x: -591.2447390900991, y: -38.29713731234641 },
			{ x: -590.88465180733, y: -39.07083997504831 },
			{ x: -590.5173647815504, y: -39.84406657189522 },
			{ x: -590.142882488037, y: -40.61680768136497 },
			{ x: -589.7612094897381, y: -41.389053887850906 },
			{ x: -589.3723504372189, y: -42.160795781777 },
			{ x: -588.9763100686042, y: -42.93202395971139 },
			{ x: -588.5730932095208, y: -43.702729024482544 },
			{ x: -588.1627047730391, y: -44.4729015852922 },
			{ x: -587.7451497596127, y: -45.24253225783075 },
			{ x: -587.3204332570173, y: -46.011611664391516 },
			{ x: -586.8885604402898, y: -46.78013043398454 },
			{ x: -586.4495365716641, y: -47.54807920245101 },
			{ x: -586.0033670005074, y: -48.31544861257781 },
			{ x: -585.5500571632552, y: -49.082229314210664 },
			{ x: -585.0896125833449, y: -49.84841196436867 },
			{ x: -584.6220388711482, y: -50.61398722735801 },
			{ x: -584.1473417239034, y: -51.37894577488599 },
			{ x: -583.6655269256454, y: -52.14327828617373 },
			{ x: -583.1766003471353, y: -52.90697544807115 },
			{ x: -582.6805679457893, y: -53.67002795516899 },
			{ x: -582.1774357656057, y: -54.432426509913505 },
			{ x: -581.6672099370912, y: -55.194161822718854 },
			{ x: -581.1498966771867, y: -55.9552246120806 },
			{ x: -580.6255022891909, y: -56.71560560468883 },
			{ x: -580.0940331626839, y: -57.475295535541086 },
			{ x: -579.5554957734495, y: -58.234285148055314 },
			{ x: -579.0098966833955, y: -58.992565194182745 },
			{ x: -578.4572425404748, y: -59.750126434520126 },
			{ x: -577.8975400786038, y: -60.50695963842283 },
			{ x: -577.3307961175802, y: -61.26305558411726 },
			{ x: -576.7570175630004, y: -62.01840505881283 },
			{ x: -576.1762114061752, y: -62.77299885881453 },
			{ x: -575.588384724044, y: -63.52682778963518 },
			{ x: -574.9935446790895, y: -64.27988266610697 },
			{ x: -574.3916985192501, y: -65.03215431249397 },
			{ x: -573.7828535778311, y: -65.78363356260364 },
			{ x: -573.1670172734163, y: -66.53431125989827 },
			{ x: -572.5441971097764, y: -67.28417825760725 },
			{ x: -571.9144006757786, y: -68.03322541883777 },
			{ x: -571.2776356452938, y: -68.78144361668649 },
			{ x: -570.6339097771029, y: -69.52882373435084 },
			{ x: -569.9832309148023, y: -70.27535666523998 },
			{ x: -569.3256069867089, y: -71.02103331308568 },
			{ x: -568.6610460057626, y: -71.7658445920533 },
			{ x: -567.9895560694291, y: -72.50978142685251 },
			{ x: -567.3111453596016, y: -73.25283475284773 },
			{ x: -566.6258221425002, y: -73.9949955161688 },
			{ x: -565.9335947685723, y: -74.73625467382078 },
			{ x: -565.2344716723899, y: -75.47660319379489 },
			{ x: -564.5284613725473, y: -76.21603205517802 },
			{ x: -563.8155724715572, y: -76.95453224826296 },
			{ x: -563.0958136557459, y: -77.69209477465773 },
			{ x: -562.3691936951474, y: -78.42871064739582 },
			{ x: -561.6357214433966, y: -79.16437089104527 },
			{ x: -560.8954058376215, y: -79.89906654181816 },
			{ x: -560.148255898334, y: -80.63278864767987 },
			{ x: -559.3942807293205, y: -81.36552826845804 },
			{ x: -558.6334895175303, y: -82.09727647595167 },
			{ x: -557.8658915329644, y: -82.82802435403978 },
			{ x: -557.0914961285617, y: -83.55776299879007 },
			{ x: -556.3103127400863, y: -84.28648351856738 },
			{ x: -555.5223508860108, y: -85.01417703414225 },
			{ x: -554.727620167402, y: -85.74083467879875 },
			{ x: -553.9261302678028, y: -86.46644759844293 },
			{ x: -553.1178909531149, y: -87.19100695171021 },
			{ x: -552.3029120714789, y: -87.91450391007362 },
			{ x: -551.4812035531555, y: -88.63692965795103 },
			{ x: -550.6527754104037, y: -89.35827539281257 },
			{ x: -549.817637737359, y: -90.07853232528832 },
			{ x: -548.9758007099101, y: -90.7976916792748 },
			{ x: -548.127274585576, y: -91.51574469204206 },
			{ x: -547.2720697033794, y: -92.23268261434083 },
			{ x: -546.4101964837221, y: -92.94849671050882 },
			{ x: -545.5416654282571, y: -93.66317825857716 },
			{ x: -544.6664871197613, y: -94.37671855037675 },
			{ x: -543.7846722220063, y: -95.08910889164426 },
			{ x: -542.8962314796282, y: -95.8003406021282 },
			{ x: -542.0011757179972, y: -96.51040501569466 },
			{ x: -541.0995158430851, y: -97.21929348043282 },
			{ x: -540.1912628413331, y: -97.92699735876046 },
			{ x: -539.2764277795175, y: -98.63350802752919 },
			{ x: -538.3550218046145, y: -99.3388168781294 },
			{ x: -537.4270561436653, y: -100.04291531659543 },
			{ x: -536.4925421036387, y: -100.74579476370987 },
			{ x: -535.5514910712932, y: -101.4474466551086 },
			{ x: -534.6039145130388, y: -102.14786244138467 },
			{ x: -533.6498239747968, y: -102.84703358819283 },
			{ x: -532.6892310818592, y: -103.54495157635326 },
			{ x: -531.7221475387474, y: -104.24160790195549 },
			{ x: -530.7485851290692, y: -104.93699407646211 },
			{ x: -529.7685557153752, y: -105.63110162681201 },
			{ x: -528.7820712390145, y: -106.32392209552373 },
			{ x: -527.7891437199893, y: -107.01544704079845 },
			{ x: -526.7897852568078, y: -107.70566803662311 },
			{ x: -525.7840080263378, y: -108.39457667287255 },
			{ x: -524.7718242836573, y: -109.0821645554124 },
			{ x: -523.7532463619058, y: -109.76842330620141 },
			{ x: -522.7282866721339, y: -110.45334456339324 },
			{ x: -521.6969577031518, y: -111.13691998143847 },
			{ x: -520.6592720213777, y: -111.81914123118645 },
			{ x: -519.615242270684, y: -112.49999999998649 },
			{ x: -518.5648811722439, y: -113.17948799178936 },
			{ x: -517.5082015243756, y: -113.85759692724832 },
			{ x: -516.4452162023875, y: -114.53431854381998 },
			{ x: -515.3759381584201, y: -115.20964459586504 },
			{ x: -514.3003804212891, y: -115.88356685474862 },
			{ x: -513.218556096326, y: -116.55607710894067 },
			{ x: -512.1304783652186, y: -117.22716716411607 },
			{ x: -511.0361604858509, y: -117.89682884325418 },
			{ x: -509.9356157921409, y: -118.56505398673879 },
			{ x: -508.82885769387826, y: -119.23183445245749 },
			{ x: -507.7158996765613, y: -119.8971621159006 },
			{ x: -506.5967553012322, y: -120.56102887026059 },
			{ x: -505.4714382043119, y: -121.2234266265305 },
			{ x: -504.33996209743407, y: -121.88434731360277 },
			{ x: -503.20234076727814, y: -122.54378287836738 },
			{ x: -502.05858807540096, y: -123.2017252858101 },
			{ x: -500.9087179580679, y: -123.85816651911037 },
			{ x: -499.75274442608384, y: -124.51309857973878 },
			{ x: -498.5906815646213, y: -125.16651348755492 },
			{ x: -497.4225435330496, y: -125.81840328090439 },
			{ x: -496.24834456476185, y: -126.46876001671569 },
			{ x: -495.0680989670021, y: -127.11757577059734 },
			{ x: -493.8818211206903, y: -127.76484263693413 },
			{ x: -492.6895254802478, y: -128.41055272898342 },
			{ x: -491.49122657342065, y: -129.05469817897168 },
			{ x: -490.2869390011029, y: -129.69727113818976 },
			{ x: -489.0766774371585, y: -130.33826377708903 },
			{ x: -487.8604566282428, y: -130.9776682853764 },
			{ x: -486.63829139362235, y: -131.61547687210975 },
			{ x: -485.410196624995, y: -132.2516817657927 },
			{ x: -484.17618728630794, y: -132.8862752144695 },
			{ x: -482.93627841357545, y: -133.5192494858191 },
			{ x: -481.69048511469595, y: -134.15059686724982 },
			{ x: -480.43882256926753, y: -134.78030966599306 },
			{ x: -479.18130602840336, y: -135.40838020919713 },
			{ x: -477.9179508145456, y: -136.0348008440206 },
			{ x: -476.6487723212789, y: -136.65956393772575 },
			{ x: -475.37378601314265, y: -137.28266187777146 },
			{ x: -474.0930074254427, y: -137.90408707190593 },
			{ x: -472.8064521640616, y: -138.52383194825944 },
			{ x: -471.5141359052698, y: -139.141888955436 },
			{ x: -470.21607439553276, y: -139.75825056260618 },
			{ x: -468.9122834513203, y: -140.37290925959823 },
			{ x: -467.6027789589138, y: -140.98585755698994 },
			{ x: -466.28757687421205, y: -141.59708798619974 },
			{ x: -464.9666932225375, y: -142.2065930995778 },
			{ x: -463.64014409844054, y: -142.81436547049654 },
			{ x: -462.3079456655037, y: -143.4203976934415 },
			{ x: -460.9701141561444, y: -144.0246823841013 },
			{ x: -459.6266658714174, y: -144.6272121794577 },
			{ x: -458.2776171808161, y: -145.2279797378752 },
			{ x: -456.922984522073, y: -145.8269777391908 },
			{ x: -455.56278440095974, y: -146.4241988848028 },
			{ x: -454.19703339108526, y: -147.01963589776008 },
			{ x: -452.82574813369484, y: -147.61328152285049 },
			{ x: -451.44894533746645, y: -148.20512852668946 },
			{ x: -450.06664177830777, y: -148.79516969780803 },
			{ x: -448.6788542991514, y: -149.38339784674073 },
			{ x: -447.2855998097498, y: -149.9698058061132 },
			{ x: -445.88689528646927, y: -150.55438643072947 },
			{ x: -444.48275777208295, y: -151.13713259765916 },
			{ x: -443.0732043755635, y: -151.71803720632414 },
			{ x: -441.65825227187395, y: -152.29709317858504 },
			{ x: -440.2379187017592, y: -152.8742934588277 },
			{ x: -438.8122209715359, y: -153.44963101404863 },
			{ x: -437.3811764528807, y: -154.02309883394145 },
			{ x: -435.94480258261956, y: -154.5946899309817 },
			{ x: -434.50311686251473, y: -155.1643973405122 },
			{ x: -433.0561368590518, y: -155.73221412082805 },
			{ x: -431.60388020322534, y: -156.2981333532609 },
			{ x: -430.14636459032454, y: -156.86214814226355 },
			{ x: -428.68360777971714, y: -157.42425161549377 },
			{ x: -427.2156275946332, y: -157.98443692389813 },
			{ x: -425.7424419219481, y: -158.54269724179537 },
			{ x: -424.2640687119642, y: -159.09902576695978 },
			{ x: -422.7805259781928, y: -159.6534157207038 },
			{ x: -421.2918317971337, y: -160.2058603479608 },
			{ x: -419.7980043080556, y: -160.7563529173674 },
			{ x: -418.29906171277537, y: -161.30488672134527 },
			{ x: -416.7950222754352, y: -161.85145507618319 },
			{ x: -415.2859043222811, y: -162.39605132211818 },
			{ x: -413.7717262414392, y: -162.93866882341686 },
			{ x: -412.2525064826913, y: -163.4793009684563 },
			{ x: -410.72826355725095, y: -164.01794116980432 },
			{ x: -409.19901603753704, y: -164.5545828643001 },
			{ x: -407.66478255694807, y: -165.08921951313394 },
			{ x: -406.1255818096348, y: -165.621844601927 },
			{ x: -404.58143255027244, y: -166.1524516408107 },
			{ x: -403.03235359383274, y: -166.68103416450563 },
			{ x: -401.47836381535376, y: -167.2075857324006 },
			{ x: -399.9194821497105, y: -167.7320999286309 },
			{ x: -398.3557275913842, y: -168.2545703621566 },
			{ x: -396.78711919423057, y: -168.77499066684035 },
			{ x: -395.213676071248, y: -169.29335450152496 },
			{ x: -393.63541739434436, y: -169.80965555011068 },
			{ x: -392.0523623941034, y: -170.3238875216322 },
			{ x: -390.4645303595509, y: -170.83604415033523 },
			{ x: -388.8719406379191, y: -171.3461191957528 },
			{ x: -387.27461263441114, y: -171.85410644278156 },
			{ x: -385.67256581196466, y: -172.35999970175715 },
			{ x: -384.06581969101455, y: -172.86379280852987 },
			{ x: -382.45439384925527, y: -173.36547962453972 },
			{ x: -380.83830792140225, y: -173.86505403689114 },
			{ x: -379.21758159895245, y: -174.36250995842758 },
			{ x: -377.5922346299446, y: -174.85784132780563 },
			{ x: -375.96228681871867, y: -175.3510421095689 },
			{ x: -374.32775802567426, y: -175.84210629422148 },
			{ x: -372.688668167029, y: -176.33102789830124 },
			{ x: -371.0450372145755, y: -176.81780096445272 },
			{ x: -369.3968851954382, y: -177.30241956149976 },
			{ x: -367.7442321918291, y: -177.7848777845178 },
			{ x: -366.0870983408038, y: -178.26516975490543 },
			{ x: -364.4255038340155, y: -178.7432896204566 },
			{ x: -362.7594689174684, y: -179.2192315554318 },
			{ x: -361.08901389127266, y: -179.69298976062854 },
			{ x: -359.4141591093952, y: -180.1645584634528 },
			{ x: -357.7349249794132, y: -180.63393191798858 },
			{ x: -356.0513319622648, y: -181.10110440506855 },
			{ x: -354.36340057199914, y: -181.56607023234338 },
			{ x: -352.67115137552804, y: -182.02882373435114 },
			{ x: -350.97460499237343, y: -182.48935927258657 },
			{ x: -349.2737820944178, y: -182.94767123556935 },
			{ x: -347.56870340565166, y: -183.4037540389129 },
			{ x: -345.85938970192035, y: -183.85760212539216 },
			{ x: -344.1458618106723, y: -184.30920996501143 },
			{ x: -342.42814061070357, y: -184.7585720550718 },
			{ x: -340.7062470319054, y: -185.20568292023788 },
			{ x: -338.98020205500757, y: -185.6505371126049 },
			{ x: -337.25002671132313, y: -186.093129211765 },
			{ x: -335.5157420824931, y: -186.53345382487296 },
			{ x: -333.77736930022786, y: -186.9715055867126 },
			{ x: -332.03492954605144, y: -187.40727915976117 },
			{ x: -330.2884440510425, y: -187.8407692342553 },
			{ x: -328.53793409557534, y: -188.2719705282553 },
			{ x: -326.7834210090616, y: -188.70087778770935 },
			{ x: -325.02492616968925, y: -189.127485786518 },
			{ x: -323.26247100416316, y: -189.55178932659732 },
			{ x: -321.49607698744353, y: -189.97378323794254 },
			{ x: -319.72576564248357, y: -190.39346237869114 },
			{ x: -317.95155853996874, y: -190.81082163518514 },
			{ x: -316.1734772980523, y: -191.22585592203373 },
			{ x: -314.3915435820932, y: -191.63856018217515 },
			{ x: -312.6057791043918, y: -192.04892938693814 },
			{ x: -310.8162056239241, y: -192.45695853610357 },
			{ x: -309.02284494607864, y: -192.86264265796487 },
			{ x: -307.22571892238904, y: -193.26597680938917 },
			{ x: -305.4248494502689, y: -193.6669560758771 },
			{ x: -303.6202584727446, y: -194.06557557162273 },
			{ x: -301.81196797818734, y: -194.46183043957345 },
			{ x: -300.0000000000462, y: -194.8557158514887 },
			{ x: -298.1843766165797, y: -195.2472270079989 },
			{ x: -296.36511995058504, y: -195.63635913866437 },
			{ x: -294.54225216913113, y: -196.02310750203276 },
			{ x: -292.71579548328623, y: -196.40746738569743 },
			{ x: -290.8857721478488, y: -196.78943410635438 },
			{ x: -289.052204461076, y: -197.16900300985967 },
			{ x: -287.2151147644108, y: -197.54616947128605 },
			{ x: -285.37452544221213, y: -197.9209288949792 },
			{ x: -283.53045892147935, y: -198.29327671461382 },
			{ x: -281.6829376715814, y: -198.6632083932492 },
			{ x: -279.83198420398185, y: -199.03071942338457 },
			{ x: -277.9776210719641, y: -199.39580532701407 },
			{ x: -276.1198708703582, y: -199.75846165568112 },
			{ x: -274.2587562352637, y: -200.11868399053284 },
			{ x: -272.3942998437753, y: -200.47646794237377 },
			{ x: -270.52652441370583, y: -200.83180915171926 },
			{ x: -268.6554527033092, y: -201.18470328884894 },
			{ x: -266.78110751100405, y: -201.53514605385902 },
			{ x: -264.90351167509465, y: -201.88313317671512 },
			{ x: -263.02268807349395, y: -202.2286604173039 },
			{ x: -261.13865962344414, y: -202.5717235654849 },
			{ x: -259.251449281237, y: -202.91231844114205 },
			{ x: -257.3610800419354, y: -203.25044089423415 },
			{ x: -255.46757493909138, y: -203.58608680484596 },
			{ x: -253.5709570444674, y: -203.9192520832379 },
			{ x: -251.67124946775422, y: -204.24993266989614 },
			{ x: -249.76847535628858, y: -204.5781245355822 },
			{ x: -247.862657894773, y: -204.90382368138168 },
			{ x: -245.95382030499172, y: -205.22702613875333 },
			{ x: -244.04198584552825, y: -205.54772796957715 },
			{ x: -242.1271778114822, y: -205.86592526620262 },
			{ x: -240.20941953418523, y: -206.181614151496 },
			{ x: -238.28873438091657, y: -206.49479077888793 },
			{ x: -236.36514575461896, y: -206.80545133242 },
			{ x: -234.4386770936126, y: -207.1135920267914 },
			{ x: -232.5093518713102, y: -207.419209107405 },
			{ x: -230.5771935959307, y: -207.72229885041313 },
			{ x: -228.64222581021286, y: -208.02285756276297 },
			{ x: -226.70447209112876, y: -208.32088158224138 },
			{ x: -224.76395604959578, y: -208.6163672775198 },
			{ x: -222.8207013301895, y: -208.90931104819825 },
			{ x: -220.8747316108555, y: -209.19970932484932 },
			{ x: -218.92607060262043, y: -209.48755856906172 },
			{ x: -216.97474204930407, y: -209.77285527348317 },
			{ x: -215.02076972722892, y: -210.05559596186336 },
			{ x: -213.06417744493106, y: -210.3357771890963 },
			{ x: -211.10498904286996, y: -210.6133955412621 },
			{ x: -209.14322839313795, y: -210.88844763566877 },
			{ x: -207.17891939916964, y: -211.16093012089334 },
			{ x: -205.2120859954502, y: -211.4308396768227 },
			{ x: -203.24275214722383, y: -211.69817301469413 },
			{ x: -201.27094185020204, y: -211.9629268771352 },
			{ x: -199.29667913027095, y: -212.22509803820375 },
			{ x: -197.319988043199, y: -212.48468330342683 },
			{ x: -195.3408926743431, y: -212.74167950983994 },
			{ x: -193.3594171383558, y: -212.9960835260254 },
			{ x: -191.3755855788912, y: -213.2478922521506 },
			{ x: -189.38942216831063, y: -213.4971026200056 },
			{ x: -187.40095110738872, y: -213.74371159304076 },
			{ x: -185.4101966250177, y: -213.98771616640354 },
			{ x: -183.41718297791272, y: -214.22911336697527 },
			{ x: -181.42193445031606, y: -214.46790025340718 },
			{ x: -179.4244753537013, y: -214.7040739161565 },
			{ x: -177.42483002647737, y: -214.9376314775216 },
			{ x: -175.42302283369142, y: -215.16857009167734 },
			{ x: -173.41907816673236, y: -215.39688694470954 },
			{ x: -171.4130204430335, y: -215.62257925464945 },
			{ x: -169.4048741057753, y: -215.8456442715075 },
			{ x: -167.39466362358698, y: -216.06607927730678 },
			{ x: -165.38241349024898, y: -216.28388158611642 },
			{ x: -163.36814822439413, y: -216.49904854408393 },
			{ x: -161.35189236920888, y: -216.7115775294679 },
			{ x: -159.33367049213476, y: -216.92146595266965 },
			{ x: -157.31350718456838, y: -217.12871125626503 },
			{ x: -155.29142706156205, y: -217.33331091503538 },
			{ x: -153.26745476152396, y: -217.53526243599845 },
			{ x: -151.24161494591772, y: -217.7345633584387 },
			{ x: -149.21393229896245, y: -217.93121125393722 },
			{ x: -147.18443152733118, y: -218.12520372640154 },
			{ x: -145.1531373598503, y: -218.31653841209456 },
			{ x: -143.12007454719824, y: -218.50521297966353 },
			{ x: -141.08526786160363, y: -218.6912251301684 },
			{ x: -139.04874209654406, y: -218.8745725971098 },
			{ x: -137.01052206644314, y: -219.0552531464567 },
			{ x: -134.97063260636872, y: -219.23326457667363 },
			{ x: -132.92909857173015, y: -219.40860471874748 },
			{ x: -130.88594483797522, y: -219.581271436214 },
			{ x: -128.84119630028763, y: -219.7512626251837 },
			{ x: -126.79487787328293, y: -219.91857621436756 },
			{ x: -124.74701449070538, y: -220.08321016510232 },
			{ x: -122.69763110512402, y: -220.24516247137524 },
			{ x: -120.64675268762852, y: -220.4044311598486 },
			{ x: -118.59440422752544, y: -220.56101428988364 },
			{ x: -116.54061073203297, y: -220.7149099535644 },
			{ x: -114.48539722597671, y: -220.86611627572074 },
			{ x: -112.42878875148462, y: -221.01463141395138 },
			{ x: -110.37081036768184, y: -221.16045355864622 },
			{ x: -108.31148715038577, y: -221.30358093300842 },
			{ x: -106.25084419179979, y: -221.44401179307607 },
			{ x: -104.18890660020804, y: -221.5817444277435 },
			{ x: -102.1256994996694, y: -221.71677715878204 },
			{ x: -100.06124802971111, y: -221.84910834086045 },
			{ x: -97.99557734502314, y: -221.97873636156504 },
			{ x: -95.92871261515072, y: -222.10565964141927 },
			{ x: -93.86067902418834, y: -222.22987663390302 },
			{ x: -91.79150177047269, y: -222.35138582547148 },
			{ x: -89.72120606627546, y: -222.4701857355735 },
			{ x: -87.64981713749674, y: -222.5862749166696 },
			{ x: -85.57736022335679, y: -222.69965195424982 },
			{ x: -83.5038605760891, y: -222.8103154668507 },
			{ x: -81.42934346063247, y: -222.91826410607226 },
			{ x: -79.3538341543232, y: -223.0234965565944 },
			{ x: -77.27735794658741, y: -223.12601153619292 },
			{ x: -75.19994013863231, y: -223.22580779575514 },
			{ x: -73.12160604313827, y: -223.32288411929517 },
			{ x: -71.04238098395037, y: -223.4172393239686 },
			{ x: -68.96229029576965, y: -223.50887226008703 },
			{ x: -66.88135932384499, y: -223.59778181113208 },
			{ x: -64.79961342366343, y: -223.68396689376894 },
			{ x: -62.71707796064182, y: -223.76742645785953 },
			{ x: -60.63377830981756, y: -223.84815948647545 },
			{ x: -58.549739855539315, y: -223.9261649959102 },
			{ x: -56.46498799115824, y: -224.00144203569124 },
			{ x: -54.37954811871776, y: -224.07398968859164 },
			{ x: -52.29344564864458, y: -224.14380707064112 },
			{ x: -50.20670599943898, y: -224.2108933311369 },
			{ x: -48.11935459736492, y: -224.27524765265412 },
			{ x: -46.03141687614076, y: -224.33686925105562 },
			{ x: -43.942918276628596, y: -224.3957573755017 },
			{ x: -41.85388424652478, y: -224.45191130845916 },
			{ x: -39.764340240049684, y: -224.50533036571002 },
			{ x: -37.6743117176375, y: -224.55601389635993 },
			{ x: -35.583824145626465, y: -224.60396128284606 },
			{ x: -33.49290299594777, y: -224.64917194094468 },
			{ x: -31.4015737458158, y: -224.69164531977813 },
			{ x: -29.30986187741746, y: -224.73138090182175 },
			{ x: -27.217792877601646, y: -224.76837820290996 },
			{ x: -25.12539223756916, y: -224.80263677224235 },
			{ x: -23.03268545256135, y: -224.83415619238906 },
			{ x: -20.939698021549972, y: -224.8629360792959 },
			{ x: -18.84645544692637, y: -224.88897608228902 },
			{ x: -16.752983234190587, y: -224.91227588407924 },
			{ x: -14.659306891641155, y: -224.93283520076577 },
			{ x: -12.565451930063439, y: -224.95065378183983 },
			{ x: -10.471443862419372, y: -224.96573141018771 },
			{ x: -8.377308203536426, y: -224.9780679020932 },
			{ x: -6.2830704697966056, y: -224.98766310724002 },
			{ x: -4.18875617882606, y: -224.9945169087137 },
			{ x: -2.094390849183366, y: -224.99862922300278 },
			{ x: -4.912393612642751e-11, y: -225 },
			{ x: 2.094390849085119, y: -224.99862922300292 },
			{ x: 4.188756178727948, y: -224.99451690871393 },
			{ x: 6.2830704696984965, y: -224.9876631072404 },
			{ x: 8.377308203438188, y: -224.9780679020937 },
			{ x: 10.471443862321138, y: -224.96573141018837 },
			{ x: 12.565451929965214, y: -224.9506537818406 },
			{ x: 14.659306891543068, y: -224.93283520076665 },
			{ x: 16.752983234092515, y: -224.91227588408026 },
			{ x: 18.84645544682817, y: -224.88897608229018 },
			{ x: 20.939698021451782, y: -224.86293607929719 },
			{ x: 23.032685452463173, y: -224.83415619239048 },
			{ x: 25.12539223747113, y: -224.8026367722439 },
			{ x: 27.217792877503634, y: -224.76837820291163 },
			{ x: 29.30986187731933, y: -224.73138090182354 },
			{ x: 31.401573745717684, y: -224.6916453197801 },
			{ x: 33.492902995849676, y: -224.64917194094673 },
			{ x: 35.58382414552852, y: -224.60396128284827 },
			{ x: 37.67431171753958, y: -224.55601389636226 },
			{ x: 39.76434023995166, y: -224.50533036571247 },
			{ x: 41.85388424642676, y: -224.45191130846172 },
			{ x: 43.94291827653061, y: -224.3957573755044 },
			{ x: 46.03141687604293, y: -224.33686925105846 },
			{ x: 48.11935459726711, y: -224.27524765265707 },
			{ x: 50.206705999341075, y: -224.21089333114 },
			{ x: 52.29344564854671, y: -224.14380707064433 },
			{ x: 54.37954811861991, y: -224.07398968859496 },
			{ x: 56.46498799106056, y: -224.0014420356947 },
			{ x: 58.549739855441665, y: -223.92616499591378 },
			{ x: 60.633778309719816, y: -223.84815948647918 },
			{ x: 62.71707796054411, y: -223.7674264578634 },
			{ x: 64.79961342356576, y: -223.68396689377292 },
			{ x: 66.88135932374749, y: -223.59778181113617 },
			{ x: 68.96229029567219, y: -223.50887226009127 },
			{ x: 71.04238098385281, y: -223.41723932397295 },
			{ x: 73.12160604304076, y: -223.32288411929966 },
			{ x: 75.19994013853484, y: -223.22580779575978 },
			{ x: 77.27735794649013, y: -223.12601153619767 },
			{ x: 79.35383415422594, y: -223.02349655659927 },
			{ x: 81.42934346053514, y: -222.91826410607726 },
			{ x: 83.50386057599178, y: -222.81031546685583 },
			{ x: 85.57736022325955, y: -222.69965195425507 },
			{ x: 87.64981713739968, y: -222.586274916675 },
			{ x: 89.72120606617845, y: -222.470185735579 },
			{ x: 91.7915017703756, y: -222.35138582547714 },
			{ x: 93.86067902409131, y: -222.2298766339088 },
			{ x: 95.92871261505388, y: -222.10565964142515 },
			{ x: 97.99557734492633, y: -221.97873636157104 },
			{ x: 100.06124802961438, y: -221.8491083408666 },
			{ x: 102.12569949957258, y: -221.71677715878832 },
			{ x: 104.18890660011128, y: -221.5817444277499 },
			{ x: 106.25084419170321, y: -221.4440117930826 },
			{ x: 108.31148715028925, y: -221.30358093301504 },
			{ x: 110.3708103675854, y: -221.160453558653 },
			{ x: 112.42878875138811, y: -221.01463141395828 },
			{ x: 114.48539722588025, y: -220.8661162757278 },
			{ x: 116.54061073193672, y: -220.71490995357155 },
			{ x: 118.59440422742927, y: -220.56101428989095 },
			{ x: 120.64675268753241, y: -220.404431159856 },
			{ x: 122.69763110502784, y: -220.24516247138277 },
			{ x: 124.74701449060929, y: -220.08321016510996 },
			{ x: 126.79487787318705, y: -219.91857621437532 },
			{ x: 128.8411963001918, y: -219.75126262519157 },
			{ x: 130.88594483787946, y: -219.58127143622204 },
			{ x: 132.92909857163434, y: -219.40860471875567 },
			{ x: 134.970632606273, y: -219.2332645766819 },
			{ x: 137.01052206634762, y: -219.05525314646508 },
			{ x: 139.04874209644862, y: -218.87457259711832 },
			{ x: 141.08526786150827, y: -218.69122513017703 },
			{ x: 143.12007454710283, y: -218.5052129796723 },
			{ x: 145.15313735975496, y: -218.31653841210348 },
			{ x: 147.18443152723606, y: -218.12520372641058 },
			{ x: 149.21393229886743, y: -217.9312112539464 },
			{ x: 151.2416149458228, y: -217.73456335844796 },
			{ x: 153.26745476142898, y: -217.53526243600786 },
			{ x: 155.29142706146715, y: -217.3333109150449 },
			{ x: 157.3135071844737, y: -217.12871125627467 },
			{ x: 159.33367049204017, y: -216.92146595267945 },
			{ x: 161.35189236911438, y: -216.7115775294778 },
			{ x: 163.3681482242996, y: -216.499048544094 },
			{ x: 165.38241349015456, y: -216.28388158612657 },
			{ x: 167.39466362349276, y: -216.06607927731707 },
			{ x: 169.40487410568116, y: -215.8456442715179 },
			{ x: 171.41302044293946, y: -215.62257925465997 },
			{ x: 173.41907816663834, y: -215.39688694472017 },
			{ x: 175.42302283359746, y: -215.16857009168808 },
			{ x: 177.42483002638363, y: -214.93763147753245 },
			{ x: 179.42447535360765, y: -214.70407391616752 },
			{ x: 181.4219344502224, y: -214.46790025341835 },
			{ x: 183.41718297781915, y: -214.22911336698652 },
			{ x: 185.41019662492428, y: -213.98771616641494 },
			{ x: 187.4009511072955, y: -213.74371159305224 },
			{ x: 189.3894221682175, y: -213.49710262001722 },
			{ x: 191.37558557879805, y: -213.24789225216233 },
			{ x: 193.3594171382628, y: -212.9960835260373 },
			{ x: 195.3408926742502, y: -212.74167950985193 },
			{ x: 197.31998804310635, y: -212.4846833034389 },
			{ x: 199.29667913017843, y: -212.22509803821598 },
			{ x: 201.27094185010952, y: -211.96292687714757 },
			{ x: 203.2427521471314, y: -211.6981730147066 },
			{ x: 205.21208599535785, y: -211.43083967683532 },
			{ x: 207.17891939907759, y: -211.16093012090604 },
			{ x: 209.14322839304597, y: -210.8884476356816 },
			{ x: 211.10498904277802, y: -210.61339554127505 },
			{ x: 213.0641774448392, y: -210.3357771891094 },
			{ x: 215.0207697271372, y: -210.05559596187658 },
			{ x: 216.9747420492126, y: -209.77285527349645 },
			{ x: 218.92607060252908, y: -209.48755856907516 },
			{ x: 220.87473161076412, y: -209.1997093248629 },
			{ x: 222.8207013300983, y: -208.90931104821195 },
			{ x: 224.76395604950469, y: -208.6163672775336 },
			{ x: 226.70447209103793, y: -208.32088158225528 },
			{ x: 228.64222581012217, y: -208.022857562777 },
			{ x: 230.57719359584001, y: -207.7222988504273 },
			{ x: 232.50935187121962, y: -207.41920910741925 },
			{ x: 234.43867709352216, y: -207.11359202680578 },
			{ x: 236.36514575452875, y: -206.80545133243447 },
			{ x: 238.28873438082653, y: -206.4947907789025 },
			{ x: 240.2094195340952, y: -206.18161415151076 },
			{ x: 242.1271778113923, y: -205.8659252662175 },
			{ x: 244.0419858454385, y: -205.54772796959213 },
			{ x: 245.95382030490222, y: -205.22702613876842 },
			{ x: 247.86265789468368, y: -204.9038236813969 },
			{ x: 249.7684753561994, y: -204.5781245355975 },
			{ x: 251.67124946766504, y: -204.2499326699116 },
			{ x: 253.5709570443785, y: -203.91925208325347 },
			{ x: 255.4675749390026, y: -203.58608680486162 },
			{ x: 257.3610800418466, y: -203.25044089424998 },
			{ x: 259.25144928114855, y: -202.91231844115794 },
			{ x: 261.13865962335575, y: -202.57172356550095 },
			{ x: 263.02268807340573, y: -202.22866041732001 },
			{ x: 264.9035116750066, y: -201.88313317673138 },
			{ x: 266.78110751091606, y: -201.53514605387542 },
			{ x: 268.65545270322144, y: -201.18470328886542 },
			{ x: 270.5265244136182, y: -200.83180915173588 },
			{ x: 272.39429984368786, y: -200.47646794239046 },
			{ x: 274.25875623517646, y: -200.11868399054967 },
			{ x: 276.11987087027103, y: -199.75846165569806 },
			{ x: 277.9776210718772, y: -199.39580532703113 },
			{ x: 279.83198420389493, y: -199.03071942340176 },
			{ x: 281.68293767149476, y: -198.6632083932665 },
			{ x: 283.5304589213929, y: -198.29327671463122 },
			{ x: 285.3745254421257, y: -197.9209288949967 },
			{ x: 287.2151147643247, y: -197.5461694713037 },
			{ x: 289.05220446098986, y: -197.16900300987743 },
			{ x: 290.885772147763, y: -196.7894341063722 },
			{ x: 292.71579548320057, y: -196.40746738571536 },
			{ x: 294.5422521690456, y: -196.02310750205086 },
			{ x: 296.3651199504997, y: -195.63635913868256 },
			{ x: 298.18437661649443, y: -195.24722700801723 },
			{ x: 299.9999999999613, y: -194.85571585150709 },
			{ x: 301.81196797810253, y: -194.46183043959198 },
			{ x: 303.6202584726599, y: -194.0655755716414 },
			{ x: 305.4248494501844, y: -193.66695607589583 },
			{ x: 307.2257189223047, y: -193.26597680940804 },
			{ x: 309.02284494599434, y: -192.86264265798386 },
			{ x: 310.8162056238401, y: -192.4569585361226 },
			{ x: 312.6057791043079, y: -192.04892938695735 },
			{ x: 314.3915435820097, y: -191.63856018219442 },
			{ x: 316.17347729796876, y: -191.22585592205317 },
			{ x: 317.9515585398853, y: -190.8108216352047 },
			{ x: 319.7257656424005, y: -190.39346237871075 },
			{ x: 321.49607698736065, y: -189.9737832379623 },
			{ x: 323.2624710040805, y: -189.55178932661715 },
			{ x: 325.02492616960683, y: -189.12748578653793 },
			{ x: 326.7834210089792, y: -188.70087778772944 },
			{ x: 328.5379340954932, y: -188.27197052827543 },
			{ x: 330.2884440509605, y: -187.8407692342756 },
			{ x: 332.0349295459697, y: -187.40727915978155 },
			{ x: 333.77736930014623, y: -186.97150558673306 },
			{ x: 335.5157420824116, y: -186.53345382489363 },
			{ x: 337.2500267112419, y: -186.0931292117857 },
			{ x: 338.9802020549265, y: -185.65053711262573 },
			{ x: 340.70624703182455, y: -185.20568292025877 },
			{ x: 342.42814061062296, y: -184.7585720550928 },
			{ x: 344.1458618105917, y: -184.3092099650326 },
			{ x: 345.8593897018401, y: -183.85760212541342 },
			{ x: 347.5687034055716, y: -183.4037540389342 },
			{ x: 349.273782094338, y: -182.9476712355908 },
			{ x: 350.9746049922938, y: -182.48935927260808 },
			{ x: 352.6711513754485, y: -182.0288237343728 },
			{ x: 354.36340057191995, y: -181.56607023236512 },
			{ x: 356.0513319621857, y: -181.1011044050904 },
			{ x: 357.73492497933444, y: -180.63393191801052 },
			{ x: 359.4141591093166, y: -180.16455846347483 },
			{ x: 361.0890138911941, y: -179.69298976065073 },
			{ x: 362.75946891739017, y: -179.21923155545406 },
			{ x: 364.42550383393746, y: -178.743289620479 },
			{ x: 366.08709834072613, y: -178.26516975492788 },
			{ x: 367.74423219175156, y: -177.7848777845403 },
			{ x: 369.3968851953607, y: -177.30241956152247 },
			{ x: 371.04503721449834, y: -176.81780096447548 },
			{ x: 372.6886681669522, y: -176.33102789832407 },
			{ x: 374.3277580255978, y: -175.84210629424436 },
			{ x: 375.96228681864255, y: -175.35104210959184 },
			{ x: 377.5922346298688, y: -174.85784132782868 },
			{ x: 379.21758159887696, y: -174.36250995845066 },
			{ x: 380.83830792132716, y: -173.86505403691424 },
			{ x: 382.4543938491805, y: -173.3654796245629 },
			{ x: 384.06581969094015, y: -172.8637928085531 },
			{ x: 385.67256581189054, y: -172.35999970178048 },
			{ x: 387.2746126343374, y: -171.85410644280492 },
			{ x: 388.87194063784574, y: -171.34611919577625 },
			{ x: 390.46453035947786, y: -170.8360441503587 },
			{ x: 392.0523623940307, y: -170.32388752165573 },
			{ x: 393.63541739427194, y: -169.80965555013427 },
			{ x: 395.213676071176, y: -169.2933545015486 },
			{ x: 396.78711919415895, y: -168.77499066686406 },
			{ x: 398.35572759131287, y: -168.25457036218035 },
			{ x: 399.9194821496395, y: -167.7320999286547 },
			{ x: 401.4783638152831, y: -167.20758573242443 },
			{ x: 403.0323535937625, y: -166.6810341645295 },
			{ x: 404.58143255020246, y: -166.15245164083464 },
			{ x: 406.12558180956495, y: -165.6218446019511 },
			{ x: 407.6647825568786, y: -165.08921951315807 },
			{ x: 409.19901603746786, y: -164.55458286432426 },
			{ x: 410.7282635571821, y: -164.01794116982853 },
			{ x: 412.25250648262283, y: -163.47930096848057 },
			{ x: 413.77172624137097, y: -162.93866882344122 },
			{ x: 415.28590432221336, y: -162.39605132214254 },
			{ x: 416.7950222753678, y: -161.8514550762076 },
			{ x: 418.2990617127083, y: -161.30488672136974 },
			{ x: 419.7980043079889, y: -160.75635291739192 },
			{ x: 421.2918317970672, y: -160.2058603479854 },
			{ x: 422.7805259781267, y: -159.6534157207284 },
			{ x: 424.2640687118985, y: -159.09902576698445 },
			{ x: 425.7424419218827, y: -158.54269724182006 },
			{ x: 427.2156275945681, y: -157.98443692392286 },
			{ x: 428.6836077796524, y: -157.42425161551856 },
			{ x: 430.1463645902602, y: -156.86214814228836 },
			{ x: 431.60388020316134, y: -156.2981333532858 },
			{ x: 433.0561368589881, y: -155.73221412085294 },
			{ x: 434.50311686245146, y: -155.16439734053714 },
			{ x: 435.9448025825565, y: -154.59468993100668 },
			{ x: 437.38117645281807, y: -154.02309883396646 },
			{ x: 438.81222097147355, y: -153.4496310140737 },
			{ x: 440.23791870169725, y: -152.8742934588528 },
			{ x: 441.65825227181216, y: -152.29709317861028 },
			{ x: 443.0732043755019, y: -151.71803720634944 },
			{ x: 444.48275777202184, y: -151.13713259768448 },
			{ x: 445.88689528640845, y: -150.5543864307548 },
			{ x: 447.28559980968936, y: -149.96980580613854 },
			{ x: 448.6788542990913, y: -149.38339784676612 },
			{ x: 450.066641778248, y: -148.79516969783342 },
			{ x: 451.44894533740705, y: -148.20512852671493 },
			{ x: 452.8257481336358, y: -147.61328152287598 },
			{ x: 454.1970333910266, y: -147.01963589778558 },
			{ x: 455.5627844009013, y: -146.42419888482834 },
			{ x: 456.922984522015, y: -145.82697773921637 },
			{ x: 458.27761718075834, y: -145.22797973790082 },
			{ x: 459.62666587136005, y: -144.6272121794833 },
			{ x: 460.9701141560874, y: -144.02468238412698 },
			{ x: 462.30794566544705, y: -143.4203976934672 },
			{ x: 463.6401440983842, y: -142.81436547052226 },
			{ x: 464.9666932224815, y: -142.2065930996035 },
			{ x: 466.28757687415634, y: -141.59708798622555 },
			{ x: 467.6027789588585, y: -140.98585755701572 },
			{ x: 468.9122834512654, y: -140.37290925962404 },
			{ x: 470.21607439547813, y: -139.758250562632 },
			{ x: 471.51413590521554, y: -139.14188895546187 },
			{ x: 472.8064521640077, y: -138.52383194828533 },
			{ x: 474.0930074253889, y: -137.9040870719319 },
			{ x: 475.3737860130892, y: -137.28266187779747 },
			{ x: 476.64877232122575, y: -136.6595639377518 },
			{ x: 477.9179508144928, y: -136.03480084404666 },
			{ x: 479.18130602835095, y: -135.4083802092232 },
			{ x: 480.43882256921546, y: -134.78030966601915 },
			{ x: 481.69048511464416, y: -134.15059686727596 },
			{ x: 482.93627841352406, y: -133.51924948584525 },
			{ x: 484.1761872862569, y: -132.88627521449564 },
			{ x: 485.41019662494426, y: -132.25168176581892 },
			{ x: 486.63829139357205, y: -131.61547687213593 },
			{ x: 487.8604566281927, y: -130.97766828540264 },
			{ x: 489.0766774371088, y: -130.33826377711526 },
			{ x: 490.2869390010535, y: -129.697271138216 },
			{ x: 491.49122657337165, y: -129.05469817899794 },
			{ x: 492.6895254801991, y: -128.4105527290097 },
			{ x: 493.8818211206419, y: -127.76484263696041 },
			{ x: 495.06809896695404, y: -127.11757577062366 },
			{ x: 496.24834456471416, y: -126.46876001674202 },
			{ x: 497.42254353300217, y: -125.81840328093074 },
			{ x: 498.5906815645743, y: -125.1665134875813 },
			{ x: 499.75274442603717, y: -124.51309857976514 },
			{ x: 500.90871795802155, y: -123.85816651913672 },
			{ x: 502.05858807535475, y: -123.20172528583657 },
			{ x: 503.2023407672323, y: -122.54378287839388 },
			{ x: 504.33996209738854, y: -121.88434731362928 },
			{ x: 505.4714382042667, y: -121.22342662655697 },
			{ x: 506.59675530118733, y: -120.56102887028709 },
			{ x: 507.7158996765168, y: -119.8971621159271 },
			{ x: 508.82885769383415, y: -119.231834452484 },
			{ x: 509.935615792097, y: -118.56505398676538 },
			{ x: 511.03616048580733, y: -117.89682884328076 },
			{ x: 512.1304783651753, y: -117.22716716414261 },
			{ x: 513.218556096283, y: -116.5560771089673 },
			{ x: 514.3003804212466, y: -115.8835668547752 },
			{ x: 515.3759381583778, y: -115.20964459589165 },
			{ x: 516.4452162023456, y: -114.53431854384664 },
			{ x: 517.5082015243339, y: -113.85759692727495 },
			{ x: 518.5648811722024, y: -113.17948799181603 },
			{ x: 519.6152422706429, y: -112.50000000001316 },
			{ x: 520.659272021337, y: -111.81914123121315 },
			{ x: 521.6969577031114, y: -111.1369199814652 },
			{ x: 522.7282866720936, y: -110.45334456341996 },
			{ x: 523.753246361866, y: -109.76842330622814 },
			{ x: 524.7718242836178, y: -109.08216455543915 },
			{ x: 525.7840080262986, y: -108.3945766728993 },
			{ x: 526.7897852567688, y: -107.70566803664993 },
			{ x: 527.7891437199505, y: -107.01544704082535 },
			{ x: 528.7820712389761, y: -106.3239220955506 },
			{ x: 529.7685557153371, y: -105.6311016268389 },
			{ x: 530.7485851290314, y: -104.93699407648897 },
			{ x: 531.72214753871, y: -104.24160790198239 },
			{ x: 532.6892310818221, y: -103.54495157638016 },
			{ x: 533.6498239747599, y: -102.84703358821974 },
			{ x: 534.6039145130023, y: -102.14786244141159 },
			{ x: 535.5514910712569, y: -101.44744665513554 },
			{ x: 536.4925421036027, y: -100.74579476373682 },
			{ x: 537.4270561436297, y: -100.04291531662234 },
			{ x: 538.3550218045791, y: -99.33881687815636 },
			{ x: 539.2764277794823, y: -98.63350802755615 },
			{ x: 540.1912628412983, y: -97.92699735878745 },
			{ x: 541.0995158430507, y: -97.21929348045977 },
			{ x: 542.001175717963, y: -96.51040501572164 },
			{ x: 542.8962314795943, y: -95.80034060215519 },
			{ x: 543.7846722219728, y: -95.08910889167122 },
			{ x: 544.6664871197281, y: -94.37671855040372 },
			{ x: 545.5416654282242, y: -93.6631782586042 },
			{ x: 546.4101964836894, y: -92.94849671053582 },
			{ x: 547.2720697033471, y: -92.23268261436779 },
			{ x: 548.1272745855439, y: -91.51574469206905 },
			{ x: 548.9758007098784, y: -90.79769167930176 },
			{ x: 549.8176377373275, y: -90.07853232531535 },
			{ x: 550.6527754103724, y: -89.35827539283966 },
			{ x: 551.4812035531246, y: -88.63692965797807 },
			{ x: 552.3029120714482, y: -87.9145039101007 },
			{ x: 553.1178909530845, y: -87.19100695173725 },
			{ x: 553.9261302677729, y: -86.46644759846994 },
			{ x: 554.7276201673724, y: -85.74083467882579 },
			{ x: 555.5223508859815, y: -85.01417703416925 },
			{ x: 556.3103127400572, y: -84.28648351859442 },
			{ x: 557.091496128533, y: -83.5577629988171 },
			{ x: 557.8658915329358, y: -82.82802435406676 },
			{ x: 558.6334895175021, y: -82.09727647597872 },
			{ x: 559.3942807292925, y: -81.36552826848506 },
			{ x: 560.1482558983064, y: -80.63278864770689 },
			{ x: 560.8954058375941, y: -79.89906654184517 },
			{ x: 561.6357214433696, y: -79.16437089107225 },
			{ x: 562.3691936951207, y: -78.42871064742283 },
			{ x: 563.0958136557194, y: -77.69209477468468 },
			{ x: 563.815572471531, y: -76.95453224828984 },
			{ x: 564.5284613725214, y: -76.216032055205 },
			{ x: 565.2344716723643, y: -75.47660319382186 },
			{ x: 565.933594768547, y: -74.7362546738477 },
			{ x: 566.6258221424752, y: -73.99499551619577 },
			{ x: 567.3111453595767, y: -73.25283475287482 },
			{ x: 567.9895560694047, y: -72.5097814268795 },
			{ x: 568.6610460057384, y: -71.76584459208031 },
			{ x: 569.325606986685, y: -71.02103331311264 },
			{ x: 569.9832309147787, y: -70.27535666526694 },
			{ x: 570.6339097770795, y: -69.52882373437781 },
			{ x: 571.2776356452707, y: -68.78144361671342 },
			{ x: 571.9144006757558, y: -68.03322541886462 },
			{ x: 572.5441971097539, y: -67.2841782576342 },
			{ x: 573.1670172733941, y: -66.53431125992522 },
			{ x: 573.7828535778093, y: -65.78363356263051 },
			{ x: 574.3916985192285, y: -65.03215431252086 },
			{ x: 574.9935446790682, y: -64.27988266613377 },
			{ x: 575.588384724023, y: -63.526827789661986 },
			{ x: 576.1762114061544, y: -62.77299885884135 },
			{ x: 576.75701756298, y: -62.01840505883961 },
			{ x: 577.33079611756, y: -61.263055584144006 },
			{ x: 577.8975400785839, y: -60.50695963844959 },
			{ x: 578.4572425404551, y: -59.75012643454688 },
			{ x: 579.0098966833762, y: -58.992565194209426 },
			{ x: 579.5554957734304, y: -58.234285148082044 },
			{ x: 580.0940331626651, y: -57.47529553556774 },
			{ x: 580.6255022891723, y: -56.715605604715485 },
			{ x: 581.1498966771685, y: -55.955224612107266 },
			{ x: 581.6672099370733, y: -55.19416182274548 },
			{ x: 582.1774357655879, y: -54.43242650994014 },
			{ x: 582.6805679457718, y: -53.67002795519575 },
			{ x: 583.1766003471181, y: -52.90697544809776 },
			{ x: 583.6655269256285, y: -52.143278286200356 },
			{ x: 584.1473417238868, y: -51.378945774912516 },
			{ x: 584.6220388711318, y: -50.613987227384655 },
			{ x: 585.0896125833289, y: -49.84841196439516 },
			{ x: 585.5500571632394, y: -49.08222931423712 },
			{ x: 586.003367000492, y: -48.315448612604335 },
			{ x: 586.4495365716488, y: -47.5480792024776 },
			{ x: 586.8885604402748, y: -46.780130434010964 },
			{ x: 587.3204332570026, y: -46.011611664417906 },
			{ x: 587.7451497595981, y: -45.24253225785716 },
			{ x: 588.1627047730249, y: -44.47290158531862 },
			{ x: 588.573093209507, y: -43.702729024508905 },
			{ x: 588.9763100685906, y: -42.93202395973769 },
			{ x: 589.3723504372055, y: -42.160795781803195 },
			{ x: 589.7612094897249, y: -41.38905388787719 },
			{ x: 590.142882488024, y: -40.61680768139119 },
			{ x: 590.5173647815378, y: -39.84406657192145 },
			{ x: 590.8846518073177, y: -39.07083997507456 },
			{ x: 591.244739090087, y: -38.29713731237253 },
			{ x: 591.5976222422961, y: -37.52296801113831 },
			{ x: 591.9432969641749, y: -36.74834150438029 },
			{ x: 592.2817590437861, y: -35.97326723067816 },
			{ x: 592.6130043570762, y: -35.197754634067174 },
			{ x: 592.9370288679254, y: -34.42181316392394 },
			{ x: 593.2538286281973, y: -33.64545227484991 },
			{ x: 593.5633997777869, y: -32.86868142655797 },
			{ x: 593.8657385446675, y: -32.091510083755495 },
			{ x: 594.1608412449365, y: -31.31394771603015 },
			{ x: 594.4487042828606, y: -30.536003797733834 },
			{ x: 594.7293241509195, y: -29.75768780786796 },
			{ x: 595.0026974298489, y: -28.9790092299671 },
			{ x: 595.2688207886815, y: -28.19997755198382 },
			{ x: 595.5276909847883, y: -27.420602266173503 },
			{ x: 595.7793048639173, y: -26.64089286897804 },
			{ x: 596.0236593602331, y: -25.860858860910515 },
			{ x: 596.2607514963532, y: -25.08050974643862 },
			{ x: 596.4905783833848, y: -24.29985503387051 },
			{ x: 596.7131372209598, y: -23.518904235237297 },
			{ x: 596.9284252972687, y: -22.737666866178444 },
			{ x: 597.1364399890947, y: -21.95615244582414 },
			{ x: 597.3371787618441, y: -21.174370496681025 },
			{ x: 597.5306391695784, y: -20.392330544516025 },
			{ x: 597.7168188550437, y: -19.610042118238518 },
			{ x: 597.8957155496992, y: -18.82751474978632 },
			{ x: 598.067327073745, y: -18.044757974008608 },
			{ x: 598.231651336149, y: -17.261781328549766 },
			{ x: 598.3886863346719, y: -16.478594353732387 },
			{ x: 598.5384301558917, y: -15.695206592443734 },
			{ x: 598.6808809752273, y: -14.91162759001552 },
			{ x: 598.8160370569603, y: -14.127866894111085 },
			{ x: 598.9438967542567, y: -13.343934054606757 },
			{ x: 599.0644585091862, y: -12.559838623477303 },
			{ x: 599.1777208527421, y: -11.775590154677687 },
			{ x: 599.2836824048584, y: -10.991198204028704 },
			{ x: 599.382341874427, y: -10.20667232909769 },
			{ x: 599.4736980593133, y: -9.422022089085385 },
			{ x: 599.5577498463712, y: -8.637257044707109 },
			{ x: 599.6344962114559, y: -7.852386758078189 },
			{ x: 599.7039362194377, y: -7.06742079259438 },
			{ x: 599.7660690242114, y: -6.282368712818158 },
			{ x: 599.8208938687088, y: -5.497240084362692 },
			{ x: 599.8684100849065, y: -4.712044473770733 },
			{ x: 599.908617093834, y: -3.926791448404074 },
			{ x: 599.941514405582, y: -3.1414905763234144 },
			{ x: 599.9671016193068, y: -2.356151426171921 },
			{ x: 599.9853784232366, y: -1.5707835670572314 },
			{ x: 599.9963445946742, y: -0.7853965684386089 },
			{ x: 600, y: 0 },
			{ x: 599.9963445946744, y: 0.7853965684099838 },
			{ x: 599.9853784232372, y: 1.570783567025028 },
			{ x: 599.9671016193076, y: 2.35615142614091 },
			{ x: 599.9415144055831, y: 3.141490576292999 },
			{ x: 599.9086170938355, y: 3.9267914483725845 },
			{ x: 599.8684100849082, y: 4.712044473739721 },
			{ x: 599.8208938687109, y: 5.497240084332019 },
			{ x: 599.7660690242137, y: 6.28236871278774 },
			{ x: 599.7039362194403, y: 7.067420792563364 },
			{ x: 599.6344962114589, y: 7.85238675804741 },
			{ x: 599.5577498463742, y: 8.637257044676527 },
			{ x: 599.4736980593168, y: 9.422022089054364 },
			{ x: 599.3823418744307, y: 10.206672329066578 },
			{ x: 599.2836824048625, y: 10.991198203997511 },
			{ x: 599.1777208527465, y: 11.7755901546469 },
			{ x: 599.0644585091909, y: 12.559838623446648 },
			{ x: 598.9438967542616, y: 13.34393405457601 },
			{ x: 598.8160370569656, y: 14.127866894080054 },
			{ x: 598.6808809752328, y: 14.9116275899848 },
			{ x: 598.5384301558975, y: 15.695206592412577 },
			{ x: 598.3886863346778, y: 16.47859435370186 },
			{ x: 598.2316513361553, y: 17.261781328518673 },
			{ x: 598.0673270737516, y: 18.04475797397777 },
			{ x: 597.8957155497061, y: 18.827514749755423 },
			{ x: 597.716818855051, y: 19.61004211820771 },
			{ x: 597.5306391695859, y: 20.392330544485294 },
			{ x: 597.3371787618519, y: 21.17437049665037 },
			{ x: 597.1364399891027, y: 21.956152445793297 },
			{ x: 596.928425297277, y: 22.737666866147666 },
			{ x: 596.7131372209683, y: 23.51890423520671 },
			{ x: 596.4905783833937, y: 24.299855033839748 },
			{ x: 596.2607514963624, y: 25.08050974640792 },
			{ x: 596.0236593602426, y: 25.860858860879652 },
			{ x: 595.7793048639271, y: 26.640892868947237 },
			{ x: 595.5276909847983, y: 27.42060226614286 },
			{ x: 595.2688207886919, y: 28.199977551953125 },
			{ x: 595.0026974298596, y: 28.979009229936263 },
			{ x: 594.7293241509304, y: 29.757687807837268 },
			{ x: 594.4487042828717, y: 30.536003797703184 },
			{ x: 594.160841244948, y: 31.313947715999365 },
			{ x: 593.8657385446793, y: 32.09151008372484 },
			{ x: 593.563399777799, y: 32.86868142652736 },
			{ x: 593.2538286282097, y: 33.645452274819334 },
			{ x: 592.937028867938, y: 34.421813163893326 },
			{ x: 592.6130043570892, y: 35.19775463403659 },
			{ x: 592.2817590437993, y: 35.973267230647615 },
			{ x: 591.9432969641884, y: 36.7483415043497 },
			{ x: 591.5976222423099, y: 37.52296801110775 },
			{ x: 591.2447390901011, y: 38.297137312342 },
			{ x: 590.884651807332, y: 39.070839975044066 },
			{ x: 590.5173647815525, y: 39.84406657189106 },
			{ x: 590.142882488039, y: 40.61680768136075 },
			{ x: 589.7612094897402, y: 41.38905388784677 },
			{ x: 589.3723504372209, y: 42.1607957817728 },
			{ x: 588.9763100686063, y: 42.93202395970739 },
			{ x: 588.573093209523, y: 43.70272902447849 },
			{ x: 588.1627047730414, y: 44.4729015852881 },
			{ x: 587.7451497596147, y: 45.242532257826845 },
			{ x: 587.3204332570194, y: 46.01161166438767 },
			{ x: 586.888560440292, y: 46.78013043398063 },
			{ x: 586.4495365716663, y: 47.54807920244734 },
			{ x: 586.0033670005096, y: 48.31544861257409 },
			{ x: 585.5500571632574, y: 49.08222931420695 },
			{ x: 585.089612583347, y: 49.84841196436506 },
			{ x: 584.6220388711505, y: 50.61398722735446 },
			{ x: 584.1473417239056, y: 51.37894577488238 },
			{ x: 583.6655269256476, y: 52.14327828617028 },
			{ x: 583.1766003471375, y: 52.90697544806765 },
			{ x: 582.6805679457915, y: 53.670027955165644 },
			{ x: 582.1774357656079, y: 54.4324265099101 },
			{ x: 581.6672099370935, y: 55.19416182271549 },
			{ x: 581.149896677189, y: 55.95522461207729 },
			{ x: 580.6255022891933, y: 56.715605604685464 },
			{ x: 580.0940331626862, y: 57.47529553553786 },
			{ x: 579.5554957734518, y: 58.23428514805213 },
			{ x: 579.0098966833978, y: 58.99256519417951 },
			{ x: 578.4572425404771, y: 59.75012643451693 },
			{ x: 577.8975400786061, y: 60.50695963841981 },
			{ x: 577.3307961175825, y: 61.26305558411428 },
			{ x: 576.7570175630028, y: 62.0184050588098 },
			{ x: 576.1762114061775, y: 62.772998858811626 },
			{ x: 575.5883847240463, y: 63.52682778963226 },
			{ x: 574.9935446790918, y: 64.27988266610414 },
			{ x: 574.3916985192524, y: 65.03215431249116 },
			{ x: 573.7828535778334, y: 65.78363356260083 },
			{ x: 573.1670172734185, y: 66.5343112598956 },
			{ x: 572.5441971097786, y: 67.28417825760464 },
			{ x: 571.9144006757808, y: 68.03322541883513 },
			{ x: 571.277635645296, y: 68.78144361668392 },
			{ x: 570.633909777105, y: 69.5288237343483 },
			{ x: 569.9832309148045, y: 70.27535666523747 },
			{ x: 569.325606986711, y: 71.02103331308328 },
			{ x: 568.6610460057648, y: 71.76584459205091 },
			{ x: 567.9895560694313, y: 72.50978142685022 },
			{ x: 567.3111453596036, y: 73.2528347528455 },
			{ x: 566.6258221425023, y: 73.99499551616648 },
			{ x: 565.9335947685744, y: 74.7362546738185 },
			{ x: 565.234471672392, y: 75.47660319379266 },
			{ x: 564.5284613725494, y: 76.21603205517584 },
			{ x: 563.8155724715593, y: 76.95453224826073 },
			{ x: 563.095813655748, y: 77.69209477465563 },
			{ x: 562.3691936951494, y: 78.42871064739374 },
			{ x: 561.6357214433987, y: 79.1643708910432 },
			{ x: 560.8954058376236, y: 79.89906654181618 },
			{ x: 560.1482558983361, y: 80.63278864767793 },
			{ x: 559.3942807293224, y: 81.36552826845616 },
			{ x: 558.6334895175322, y: 82.09727647594983 },
			{ x: 557.8658915329662, y: 82.82802435403802 },
			{ x: 557.0914961285637, y: 83.5577629987883 },
			{ x: 556.3103127400881, y: 84.28648351856567 },
			{ x: 555.5223508860126, y: 85.01417703414059 },
			{ x: 554.7276201674038, y: 85.74083467879719 },
			{ x: 553.9261302678046, y: 86.46644759844138 },
			{ x: 553.1178909531164, y: 87.19100695170872 },
			{ x: 552.3029120714806, y: 87.91450391007214 },
			{ x: 551.4812035531571, y: 88.63692965794957 },
			{ x: 550.6527754104053, y: 89.35827539281127 },
			{ x: 549.8176377373605, y: 90.078532325287 },
			{ x: 548.9758007099117, y: 90.79769167927337 },
			{ x: 548.1272745855775, y: 91.51574469204071 },
			{ x: 547.2720697033809, y: 92.23268261433954 },
			{ x: 546.4101964837236, y: 92.94849671050756 },
			{ x: 545.5416654282586, y: 93.66317825857595 },
			{ x: 544.6664871197627, y: 94.37671855037563 },
			{ x: 543.7846722220077, y: 95.08910889164314 },
			{ x: 542.8962314796295, y: 95.80034060212715 },
			{ x: 542.0011757179985, y: 96.5104050156936 },
			{ x: 541.0995158430864, y: 97.21929348043182 },
			{ x: 540.1912628413344, y: 97.92699735875951 },
			{ x: 539.2764277795186, y: 98.63350802752828 },
			{ x: 538.3550218046156, y: 99.33881687812853 },
			{ x: 537.4270561436664, y: 100.04291531659459 },
			{ x: 536.4925421036396, y: 100.74579476370914 },
			{ x: 535.5514910712942, y: 101.44744665510788 },
			{ x: 534.6039145130397, y: 102.14786244138399 },
			{ x: 533.6498239747978, y: 102.8470335881922 },
			{ x: 532.6892310818602, y: 103.54495157635263 },
			{ x: 531.7221475387483, y: 104.24160790195492 },
			{ x: 530.74858512907, y: 104.9369940764616 },
			{ x: 529.7685557153759, y: 105.63110162681149 },
			{ x: 528.7820712390152, y: 106.32392209552324 },
			{ x: 527.7891437199899, y: 107.01544704079808 },
			{ x: 526.7897852568084, y: 107.70566803662271 },
			{ x: 525.7840080263384, y: 108.39457667287212 },
			{ x: 524.7718242836578, y: 109.08216455541205 },
			{ x: 523.7532463619063, y: 109.76842330620106 },
			{ x: 522.7282866721343, y: 110.45334456339293 },
			{ x: 521.6969577031522, y: 111.13691998143825 },
			{ x: 520.659272021378, y: 111.81914123118628 },
			{ x: 519.6152422706842, y: 112.49999999998633 },
			{ x: 518.564881172244, y: 113.17948799178926 },
			{ x: 517.5082015243757, y: 113.85759692724822 },
			{ x: 516.4452162023877, y: 114.53431854381994 },
			{ x: 515.3759381584201, y: 115.20964459586504 },
			{ x: 514.3003804212891, y: 115.88356685474866 },
			{ x: 513.2185560963258, y: 116.55607710894077 },
			{ x: 512.1304783652184, y: 117.22716716411621 },
			{ x: 511.03616048585064, y: 117.89682884325434 },
			{ x: 509.9356157921406, y: 118.56505398673902 },
			{ x: 508.828857693878, y: 119.23183445245769 },
			{ x: 507.71589967656087, y: 119.89716211590088 },
			{ x: 506.5967553012316, y: 120.56102887026091 },
			{ x: 505.4714382043112, y: 121.2234266265309 },
			{ x: 504.3399620974334, y: 121.88434731360317 },
			{ x: 503.2023407672773, y: 122.54378287836788 },
			{ x: 502.05858807540005, y: 123.20172528581062 },
			{ x: 500.90871795806703, y: 123.85816651911087 },
			{ x: 499.7527444260829, y: 124.5130985797393 },
			{ x: 498.5906815646203, y: 125.16651348755549 },
			{ x: 497.42254353304844, y: 125.81840328090502 },
			{ x: 496.2483445647606, y: 126.46876001671642 },
			{ x: 495.06809896700076, y: 127.11757577059808 },
			{ x: 493.88182112068887, y: 127.76484263693489 },
			{ x: 492.6895254802463, y: 128.41055272898427 },
			{ x: 491.491226573419, y: 129.05469817897256 },
			{ x: 490.28693900110113, y: 129.6972711381907 },
			{ x: 489.07667743715666, y: 130.33826377709002 },
			{ x: 487.86045662824085, y: 130.9776682853774 },
			{ x: 486.63829139362036, y: 131.6154768721108 },
			{ x: 485.4101966249929, y: 132.25168176579385 },
			{ x: 484.1761872863057, y: 132.88627521447063 },
			{ x: 482.93627841357306, y: 133.51924948582032 },
			{ x: 481.69048511469344, y: 134.15059686725107 },
			{ x: 480.4388225692649, y: 134.78030966599434 },
			{ x: 479.1813060284006, y: 135.40838020919847 },
			{ x: 477.9179508145428, y: 136.03480084402196 },
			{ x: 476.648772321276, y: 136.6595639377272 },
			{ x: 475.3737860131397, y: 137.2826618777729 },
			{ x: 474.09300742543957, y: 137.90408707190747 },
			{ x: 472.8064521640586, y: 138.5238319482609 },
			{ x: 471.51413590526664, y: 139.14188895543754 },
			{ x: 470.21607439552946, y: 139.7582505626077 },
			{ x: 468.91228345131697, y: 140.37290925959982 },
			{ x: 467.60277895891033, y: 140.98585755699156 },
			{ x: 466.2875768742084, y: 141.59708798620144 },
			{ x: 464.96669322253376, y: 142.2065930995795 },
			{ x: 463.6401440984367, y: 142.8143654704983 },
			{ x: 462.30794566549974, y: 143.4203976934433 },
			{ x: 460.9701141561403, y: 144.02468238410313 },
			{ x: 459.6266658714132, y: 144.62721217945958 },
			{ x: 458.2776171808118, y: 145.22797973787712 },
			{ x: 456.9229845220686, y: 145.82697773919276 },
			{ x: 455.56278440095514, y: 146.4241988848048 },
			{ x: 454.1970333910806, y: 147.0196358977621 },
			{ x: 452.82574813369, y: 147.6132815228526 },
			{ x: 451.4489453374615, y: 148.2051285266916 },
			{ x: 450.06664177830265, y: 148.7951696978102 },
			{ x: 448.6788542991462, y: 149.38339784674292 },
			{ x: 447.2855998097445, y: 149.96980580611543 },
			{ x: 445.8868952864638, y: 150.55438643073174 },
			{ x: 444.4827577720774, y: 151.1371325976615 },
			{ x: 443.0732043755577, y: 151.71803720632653 },
			{ x: 441.6582522718681, y: 152.29709317858746 },
			{ x: 440.2379187017534, y: 152.87429345883007 },
			{ x: 438.81222097152994, y: 153.44963101405108 },
			{ x: 437.3811764528746, y: 154.0230988339439 },
			{ x: 435.94480258261336, y: 154.59468993098415 },
			{ x: 434.5031168625084, y: 155.1643973405147 },
			{ x: 433.05613685904535, y: 155.73221412083058 },
			{ x: 431.60388020321875, y: 156.2981333532635 },
			{ x: 430.14636459031783, y: 156.86214814226616 },
			{ x: 428.68360777971026, y: 157.42425161549642 },
			{ x: 427.2156275946262, y: 157.98443692390077 },
			{ x: 425.74244192194095, y: 158.54269724179807 },
			{ x: 424.264068711957, y: 159.0990257669625 },
			{ x: 422.7805259781853, y: 159.65341572070656 },
			{ x: 421.29183179712606, y: 160.2058603479636 },
			{ x: 419.79800430804795, y: 160.7563529173702 },
			{ x: 418.2990617127675, y: 161.30488672134814 },
			{ x: 416.7950222754272, y: 161.85145507618608 },
			{ x: 415.28590432227304, y: 162.39605132212108 },
			{ x: 413.7717262414309, y: 162.93866882341985 },
			{ x: 412.2525064826829, y: 163.47930096845926 },
			{ x: 410.7282635572424, y: 164.0179411698073 },
			{ x: 409.19901603752834, y: 164.55458286430314 },
			{ x: 407.6647825569392, y: 165.089219513137 },
			{ x: 406.1255818096258, y: 165.6218446019301 },
			{ x: 404.5814325502635, y: 166.15245164081375 },
			{ x: 403.0323535938237, y: 166.6810341645087 },
			{ x: 401.4783638153445, y: 167.20758573240371 },
			{ x: 399.91948214970114, y: 167.73209992863406 },
			{ x: 398.35572759137466, y: 168.2545703621598 },
			{ x: 396.78711919422096, y: 168.77499066684354 },
			{ x: 395.2136760712381, y: 169.2933545015282 },
			{ x: 393.6354173943343, y: 169.80965555011397 },
			{ x: 392.0523623940933, y: 170.3238875216355 },
			{ x: 390.4645303595406, y: 170.83604415033852 },
			{ x: 388.8719406379087, y: 171.34611919575613 },
			{ x: 387.27461263440046, y: 171.85410644278494 },
			{ x: 385.67256581195386, y: 172.35999970176053 },
			{ x: 384.06581969100364, y: 172.86379280853328 },
			{ x: 382.45439384924424, y: 173.36547962454316 },
			{ x: 380.83830792139105, y: 173.86505403689458 },
			{ x: 379.2175815989411, y: 174.36250995843108 },
			{ x: 377.5922346299331, y: 174.85784132780913 },
			{ x: 375.96228681870696, y: 175.35104210957243 },
			{ x: 374.32775802566243, y: 175.84210629422503 },
			{ x: 372.688668167017, y: 176.3310278983048 },
			{ x: 371.0450372145633, y: 176.81780096445635 },
			{ x: 369.3968851954259, y: 177.3024195615034 },
			{ x: 367.7442321918169, y: 177.7848777845213 },
			{ x: 366.0870983407916, y: 178.26516975490895 },
			{ x: 364.4255038340031, y: 178.74328962046016 },
			{ x: 362.75946891745605, y: 179.2192315554353 },
			{ x: 361.0890138912602, y: 179.69298976063206 },
			{ x: 359.4141591093828, y: 180.16455846345625 },
			{ x: 357.73492497940083, y: 180.63393191799204 },
			{ x: 356.0513319622522, y: 181.10110440507202 },
			{ x: 354.36340057198663, y: 181.56607023234682 },
			{ x: 352.67115137551536, y: 182.02882373435457 },
			{ x: 350.9746049923608, y: 182.48935927258995 },
			{ x: 349.27378209440525, y: 182.94767123557276 },
			{ x: 347.5687034056389, y: 183.40375403891628 },
			{ x: 345.8593897019076, y: 183.85760212539554 },
			{ x: 344.1458618106594, y: 184.30920996501482 },
			{ x: 342.4281406106909, y: 184.75857205507512 },
			{ x: 340.70624703189264, y: 185.2056829202412 },
			{ x: 338.9802020549947, y: 185.6505371126082 },
			{ x: 337.25002671131034, y: 186.09312921176826 },
			{ x: 335.51574208248013, y: 186.5334538248763 },
			{ x: 333.777369300215, y: 186.97150558671578 },
			{ x: 332.0349295460386, y: 187.40727915976439 },
			{ x: 330.2884440510295, y: 187.84076923425852 },
			{ x: 328.5379340955623, y: 188.2719705282585 },
			{ x: 326.7834210090485, y: 188.70087778771256 },
			{ x: 325.0249261696763, y: 189.12748578652113 },
			{ x: 323.26247100415026, y: 189.5517893266004 },
			{ x: 321.49607698743034, y: 189.9737832379457 },
			{ x: 319.72576564247044, y: 190.39346237869424 },
			{ x: 317.9515585399555, y: 190.81082163518823 },
			{ x: 316.17347729803913, y: 191.2258559220368 },
			{ x: 314.3915435820801, y: 191.6385601821782 },
			{ x: 312.60577910437837, y: 192.0489293869412 },
			{ x: 310.81620562391083, y: 192.45695853610655 },
			{ x: 309.0228449460653, y: 192.86264265796788 },
			{ x: 307.22571892237573, y: 193.26597680939216 },
			{ x: 305.42484945025564, y: 193.66695607588002 },
			{ x: 303.6202584727311, y: 194.06557557162571 },
			{ x: 301.81196797817387, y: 194.4618304395764 },
			{ x: 300.00000000003297, y: 194.85571585149157 },
			{ x: 298.1843766165662, y: 195.2472270080018 },
			{ x: 296.3651199505716, y: 195.63635913866725 },
			{ x: 294.5422521691175, y: 196.02310750203563 },
			{ x: 292.7157954832727, y: 196.40746738570024 },
			{ x: 290.8857721478354, y: 196.78943410635713 },
			{ x: 289.0522044610624, y: 197.16900300986245 },
			{ x: 287.21511476439736, y: 197.54616947128883 },
			{ x: 285.3745254421984, y: 197.92092889498196 },
			{ x: 283.53045892146565, y: 198.29327671461658 },
			{ x: 281.6829376715678, y: 198.66320839325192 },
			{ x: 279.8319842039681, y: 199.0307194233873 },
			{ x: 277.97762107195047, y: 199.39580532701675 },
			{ x: 276.11987087034436, y: 199.75846165568382 },
			{ x: 274.25875623524996, y: 200.1186839905355 },
			{ x: 272.3942998437616, y: 200.47646794237636 },
			{ x: 270.526524413692, y: 200.8318091517219 },
			{ x: 268.65545270329545, y: 201.18470328885152 },
			{ x: 266.78110751099007, y: 201.53514605386164 },
			{ x: 264.9035116750807, y: 201.88313317671768 },
			{ x: 263.02268807348014, y: 202.2286604173064 },
			{ x: 261.1386596234302, y: 202.57172356548742 },
			{ x: 259.25144928122313, y: 202.91231844114452 },
			{ x: 257.36108004192135, y: 203.25044089423668 },
			{ x: 255.46757493907748, y: 203.5860868048484 },
			{ x: 253.57095704445348, y: 203.9192520832403 },
			{ x: 251.67124946774013, y: 204.24993266989858 },
			{ x: 249.76847535627462, y: 204.5781245355846 },
			{ x: 247.86265789475902, y: 204.90382368138407 },
			{ x: 245.95382030497765, y: 205.2270261387557 },
			{ x: 244.04198584551406, y: 205.54772796957954 },
			{ x: 242.12717781146802, y: 205.86592526620498 },
			{ x: 240.20941953417102, y: 206.1816141514983 },
			{ x: 238.28873438090244, y: 206.49479077889023 },
			{ x: 236.3651457546048, y: 206.80545133242228 },
			{ x: 234.4386770935983, y: 207.11359202679367 },
			{ x: 232.5093518712959, y: 207.41920910740726 },
			{ x: 230.57719359591638, y: 207.72229885041537 },
			{ x: 228.64222581019865, y: 208.02285756276518 },
			{ x: 226.7044720911145, y: 208.3208815822436 },
			{ x: 224.7639560495814, y: 208.616367277522 },
			{ x: 222.8207013301751, y: 208.9093110482004 },
			{ x: 220.87473161084102, y: 209.19970932485148 },
			{ x: 218.9260706026061, y: 209.48755856906382 },
			{ x: 216.97474204928972, y: 209.77285527348525 },
			{ x: 215.02076972721443, y: 210.05559596186544 },
			{ x: 213.06417744491654, y: 210.33577718909837 },
			{ x: 211.10498904285544, y: 210.61339554126414 },
			{ x: 209.1432283931235, y: 210.8884476356708 },
			{ x: 207.17891939915523, y: 211.16093012089533 },
			{ x: 205.2120859954356, y: 211.4308396768247 },
			{ x: 203.24275214720922, y: 211.69817301469607 },
			{ x: 201.27094185018746, y: 211.96292687713716 },
			{ x: 199.29667913025648, y: 212.22509803820566 },
			{ x: 197.31998804318448, y: 212.48468330342874 },
			{ x: 195.34089267432842, y: 212.74167950984184 },
			{ x: 193.35941713834112, y: 212.99608352602726 },
			{ x: 191.3755855788765, y: 213.24789225215244 },
			{ x: 189.38942216829605, y: 213.4971026200074 },
			{ x: 187.4009511073741, y: 213.74371159304255 },
			{ x: 185.41019662500295, y: 213.98771616640533 },
			{ x: 183.41718297789794, y: 214.22911336697706 },
			{ x: 181.42193445030128, y: 214.46790025340897 },
			{ x: 179.4244753536866, y: 214.70407391615825 },
			{ x: 177.42483002646267, y: 214.93763147752333 },
			{ x: 175.42302283367655, y: 215.16857009167902 },
			{ x: 173.41907816671755, y: 215.3968869447112 },
			{ x: 171.41302044301875, y: 215.6225792546511 },
			{ x: 169.40487410576054, y: 215.84564427150912 },
			{ x: 167.39466362357223, y: 216.0660792773084 },
			{ x: 165.38241349023411, y: 216.28388158611804 },
			{ x: 163.3681482243792, y: 216.49904854408553 },
			{ x: 161.35189236919405, y: 216.71157752946945 },
			{ x: 159.33367049211995, y: 216.9214659526712 },
			{ x: 157.31350718455354, y: 217.12871125626654 },
			{ x: 155.29142706154704, y: 217.33331091503686 },
			{ x: 153.26745476150896, y: 217.53526243599993 },
			{ x: 151.24161494590282, y: 217.73456335844014 },
			{ x: 149.21393229894755, y: 217.93121125393867 },
			{ x: 147.18443152731626, y: 218.12520372640296 },
			{ x: 145.15313735983526, y: 218.31653841209595 },
			{ x: 143.12007454718318, y: 218.50521297966492 },
			{ x: 141.08526786158868, y: 218.69122513016976 },
			{ x: 139.04874209652908, y: 218.87457259711113 },
			{ x: 137.01052206642817, y: 219.05525314645803 },
			{ x: 134.97063260635363, y: 219.23326457667494 },
			{ x: 132.929098571715, y: 219.4086047187488 },
			{ x: 130.88594483796018, y: 219.58127143621525 },
			{ x: 128.8411963002726, y: 219.75126262518492 },
			{ x: 126.79487787326791, y: 219.91857621436876 },
			{ x: 124.7470144906902, y: 220.0832101651035 },
			{ x: 122.69763110510883, y: 220.2451624713764 },
			{ x: 120.64675268761344, y: 220.40443115984974 },
			{ x: 118.59440422751037, y: 220.5610142898848 },
			{ x: 116.54061073201788, y: 220.71490995356552 },
			{ x: 114.48539722596148, y: 220.86611627572188 },
			{ x: 112.42878875146938, y: 221.0146314139525 },
			{ x: 110.37081036766672, y: 221.16045355864728 },
			{ x: 108.31148715037062, y: 221.30358093300944 },
			{ x: 106.25084419178465, y: 221.4440117930771 },
			{ x: 104.18890660019275, y: 221.58174442774452 },
			{ x: 102.1256994996541, y: 221.71677715878303 },
			{ x: 100.06124802969596, y: 221.84910834086142 },
			{ x: 97.99557734500797, y: 221.97873636156595 },
			{ x: 95.92871261513555, y: 222.10565964142017 },
			{ x: 93.86067902417302, y: 222.22987663390396 },
			{ x: 91.79150177045737, y: 222.3513858254724 },
			{ x: 89.72120606626025, y: 222.47018573557435 },
			{ x: 87.64981713748153, y: 222.58627491667045 },
			{ x: 85.57736022334157, y: 222.69965195425064 },
			{ x: 83.50386057607372, y: 222.8103154668515 },
			{ x: 81.4293434606171, y: 222.91826410607302 },
			{ x: 79.35383415430795, y: 223.02349655659515 },
			{ x: 77.27735794657217, y: 223.12601153619366 },
			{ x: 75.19994013861707, y: 223.22580779575588 },
			{ x: 73.12160604312288, y: 223.32288411929588 },
			{ x: 71.04238098393496, y: 223.41723932396928 },
			{ x: 68.96229029575437, y: 223.5088722600877 },
			{ x: 66.8813593238297, y: 223.59778181113273 },
			{ x: 64.79961342364814, y: 223.68396689376956 },
			{ x: 62.71707796062639, y: 223.76742645786015 },
			{ x: 60.633778309802125, y: 223.84815948647602 },
			{ x: 58.54973985552401, y: 223.92616499591077 },
			{ x: 56.46498799114293, y: 224.00144203569178 },
			{ x: 54.37954811870243, y: 224.07398968859215 },
			{ x: 52.293445648629124, y: 224.14380707064163 },
			{ x: 50.20670599942352, y: 224.2108933311374 },
			{ x: 48.119354597349584, y: 224.27524765265457 },
			{ x: 46.03141687612543, y: 224.33686925105607 },
			{ x: 43.94291827661326, y: 224.39575737550211 },
			{ x: 41.853884246509296, y: 224.45191130845956 },
			{ x: 39.76434024003421, y: 224.5053303657104 },
			{ x: 37.67431171762215, y: 224.5560138963603 },
			{ x: 35.58382414561111, y: 224.60396128284643 },
			{ x: 33.49290299593242, y: 224.649171940945 },
			{ x: 31.401573745800306, y: 224.69164531977847 },
			{ x: 29.309861877401968, y: 224.73138090182204 },
			{ x: 27.21779287758628, y: 224.7683782029102 },
			{ x: 25.125392237553793, y: 224.80263677224258 },
			{ x: 23.03268545254598, y: 224.8341561923893 },
			{ x: 20.93969802153447, y: 224.8629360792961 },
			{ x: 18.84645544691086, y: 224.88897608228922 },
			{ x: 16.752983234175215, y: 224.91227588407938 },
			{ x: 14.659306891625777, y: 224.9328352007659 },
			{ x: 12.565451930047928, y: 224.95065378183997 },
			{ x: 10.47144386240386, y: 224.9657314101878 },
			{ x: 8.377308203520915, y: 224.97806790209324 },
			{ x: 6.283070469781227, y: 224.98766310724008 },
			{ x: 4.18875617881068, y: 224.99451690871373 },
			{ x: 2.094390849167852, y: 224.99862922300278 },
			{ x: 3.3609883668639155e-11, y: 225 },
			{ x: -2.0943908491006327, y: 224.9986292230029 },
			{ x: -4.188756178743328, y: 224.9945169087139 },
			{ x: -6.283070469713877, y: 224.98766310724037 },
			{ x: -8.377308203453701, y: 224.97806790209364 },
			{ x: -10.47144386233665, y: 224.96573141018825 },
			{ x: -12.565451929980725, y: 224.9506537818405 },
			{ x: -14.659306891558446, y: 224.9328352007665 },
			{ x: -16.752983234107887, y: 224.9122758840801 },
			{ x: -18.846455446843677, y: 224.88897608229 },
			{ x: -20.939698021467287, y: 224.86293607929696 },
			{ x: -23.032685452478677, y: 224.83415619239025 },
			{ x: -25.125392237486498, y: 224.80263677224366 },
			{ x: -27.217792877518995, y: 224.76837820291138 },
			{ x: -29.309861877334825, y: 224.73138090182326 },
			{ x: -31.401573745733177, y: 224.69164531977975 },
			{ x: -33.49290299586517, y: 224.64917194094642 },
			{ x: -35.58382414554387, y: 224.60396128284793 },
			{ x: -37.674311717554936, y: 224.55601389636186 },
			{ x: -39.76434023996713, y: 224.50533036571207 },
			{ x: -41.85388424644224, y: 224.45191130846132 },
			{ x: -43.94291827654609, y: 224.39575737550396 },
			{ x: -46.03141687605827, y: 224.336869251058 },
			{ x: -48.11935459728245, y: 224.27524765265662 },
			{ x: -50.20670599935653, y: 224.21089333113952 },
			{ x: -52.29344564856216, y: 224.14380707064382 },
			{ x: -54.379548118635356, y: 224.07398968859445 },
			{ x: -56.46498799107587, y: 224.00144203569417 },
			{ x: -58.549739855456984, y: 223.9261649959132 },
			{ x: -60.63377830973525, y: 223.84815948647858 },
			{ x: -62.71707796055954, y: 223.7674264578628 },
			{ x: -64.79961342358118, y: 223.6839668937723 },
			{ x: -66.88135932376277, y: 223.59778181113555 },
			{ x: -68.96229029568747, y: 223.5088722600906 },
			{ x: -71.04238098386821, y: 223.41723932397227 },
			{ x: -73.12160604305615, y: 223.32288411929895 },
			{ x: -75.19994013855023, y: 223.22580779575904 },
			{ x: -77.27735794650536, y: 223.1260115361969 },
			{ x: -79.35383415424118, y: 223.0234965565985 },
			{ x: -81.4293434605505, y: 222.91826410607646 },
			{ x: -83.50386057600716, y: 222.810315466855 },
			{ x: -85.57736022327491, y: 222.69965195425425 },
			{ x: -87.6498171374149, y: 222.58627491667414 },
			{ x: -89.72120606619366, y: 222.47018573557813 },
			{ x: -91.79150177039094, y: 222.35138582547626 },
			{ x: -93.86067902410663, y: 222.22987663390788 },
			{ x: -95.92871261506905, y: 222.10565964142424 },
			{ x: -97.99557734494152, y: 221.9787363615701 },
			{ x: -100.06124802962955, y: 221.84910834086566 },
			{ x: -102.12569949958787, y: 221.71677715878732 },
			{ x: -104.18890660012656, y: 221.5817444277489 },
			{ x: -106.25084419171836, y: 221.44401179308156 },
			{ x: -108.31148715030439, y: 221.30358093301402 },
			{ x: -110.37081036760053, y: 221.16045355865194 },
			{ x: -112.42878875140335, y: 221.0146314139572 },
			{ x: -114.4853972258955, y: 220.86611627572668 },
			{ x: -116.54061073195182, y: 220.7149099535704 },
			{ x: -118.59440422744434, y: 220.56101428988978 },
			{ x: -120.64675268754748, y: 220.40443115985482 },
			{ x: -122.69763110504303, y: 220.24516247138158 },
			{ x: -124.74701449062447, y: 220.08321016510877 },
			{ x: -126.79487787320207, y: 219.9185762143741 },
			{ x: -128.84119630020683, y: 219.75126262519032 },
			{ x: -130.88594483789447, y: 219.58127143622076 },
			{ x: -132.92909857164946, y: 219.40860471875436 },
			{ x: -134.97063260628812, y: 219.2332645766806 },
			{ x: -137.0105220663626, y: 219.05525314646377 },
			{ x: -139.04874209646357, y: 218.87457259711698 },
			{ x: -141.08526786152322, y: 218.6912251301757 },
			{ x: -143.1200745471179, y: 218.50521297967094 },
			{ x: -145.15313735977003, y: 218.3165384121021 },
			{ x: -147.18443152725098, y: 218.12520372640915 },
			{ x: -149.21393229888233, y: 217.93121125394495 },
			{ x: -151.24161494583765, y: 217.7345633584465 },
			{ x: -153.26745476144396, y: 217.53526243600638 },
			{ x: -155.29142706148212, y: 217.33331091504343 },
			{ x: -157.31350718448851, y: 217.12871125627316 },
			{ x: -159.333670492055, y: 216.9214659526779 },
			{ x: -161.3518923691292, y: 216.71157752947624 },
			{ x: -163.36814822431452, y: 216.49904854409237 },
			{ x: -165.38241349016948, y: 216.28388158612498 },
			{ x: -167.39466362350757, y: 216.06607927731545 },
			{ x: -169.4048741056959, y: 215.84564427151625 },
			{ x: -171.41302044295418, y: 215.62257925465832 },
			{ x: -173.4190781666532, y: 215.3968869447185 },
			{ x: -175.4230228336123, y: 215.1685700916864 },
			{ x: -177.42483002639833, y: 214.93763147753077 },
			{ x: -179.4244753536223, y: 214.70407391616578 },
			{ x: -181.42193445023707, y: 214.46790025341662 },
			{ x: -183.4171829778339, y: 214.22911336698476 },
			{ x: -185.41019662493903, y: 213.98771616641315 },
			{ x: -187.4009511073101, y: 213.74371159305045 },
			{ x: -189.38942216823213, y: 213.4971026200154 },
			{ x: -191.37558557881263, y: 213.24789225216048 },
			{ x: -193.35941713827748, y: 212.99608352603542 },
			{ x: -195.34089267426486, y: 212.74167950985003 },
			{ x: -197.31998804312087, y: 212.48468330343704 },
			{ x: -199.29667913019293, y: 212.22509803821407 },
			{ x: -201.27094185012402, y: 211.96292687714563 },
			{ x: -203.24275214714598, y: 211.69817301470462 },
			{ x: -205.2120859953724, y: 211.43083967683333 },
			{ x: -207.17891939909202, y: 211.16093012090406 },
			{ x: -209.1432283930604, y: 210.8884476356796 },
			{ x: -211.1049890427924, y: 210.61339554127304 },
			{ x: -213.06417744485373, y: 210.3357771891073 },
			{ x: -215.02076972715167, y: 210.0555959618745 },
			{ x: -216.97474204922693, y: 209.77285527349437 },
			{ x: -218.92607060254338, y: 209.48755856907303 },
			{ x: -220.8747316107784, y: 209.19970932486078 },
			{ x: -222.82070133011268, y: 208.9093110482098 },
			{ x: -224.76395604951907, y: 208.61636727753145 },
			{ x: -226.70447209105217, y: 208.32088158225312 },
			{ x: -228.6422258101364, y: 208.0228575627748 },
			{ x: -230.57719359585423, y: 207.72229885042506 },
			{ x: -232.50935187123392, y: 207.419209107417 },
			{ x: -234.43867709353646, y: 207.1135920268035 },
			{ x: -236.3651457545429, y: 206.8054513324322 },
			{ x: -238.28873438084065, y: 206.49479077890027 },
			{ x: -240.20941953410932, y: 206.18161415150843 },
			{ x: -242.1271778114065, y: 205.86592526621513 },
			{ x: -244.04198584545264, y: 205.5477279695898 },
			{ x: -245.95382030491623, y: 205.22702613876604 },
			{ x: -247.8626578946977, y: 204.9038236813945 },
			{ x: -249.76847535621351, y: 204.5781245355951 },
			{ x: -251.671249467679, y: 204.24993266990919 },
			{ x: -253.57095704439254, y: 203.919252083251 },
			{ x: -255.46757493901666, y: 203.58608680485915 },
			{ x: -257.36108004186053, y: 203.25044089424748 },
			{ x: -259.25144928116254, y: 202.91231844115543 },
			{ x: -261.13865962336956, y: 202.57172356549842 },
			{ x: -263.0226880734197, y: 202.22866041731746 },
			{ x: -264.9035116750205, y: 201.8831331767288 },
			{ x: -266.7811075109298, y: 201.53514605387284 },
			{ x: -268.6554527032353, y: 201.18470328886283 },
			{ x: -270.5265244136319, y: 200.83180915173327 },
			{ x: -272.3942998437017, y: 200.4764679423878 },
			{ x: -274.2587562351903, y: 200.118683990547 },
			{ x: -276.1198708702847, y: 199.7584616556954 },
			{ x: -277.97762107189095, y: 199.39580532702843 },
			{ x: -279.8319842039085, y: 199.03071942339906 },
			{ x: -281.68293767150845, y: 198.66320839326374 },
			{ x: -283.53045892140653, y: 198.29327671462843 },
			{ x: -285.3745254421392, y: 197.92092889499398 },
			{ x: -287.2151147643383, y: 197.54616947130089 },
			{ x: -289.0522044610033, y: 197.16900300987461 },
			{ x: -290.88577214777655, y: 196.78943410636938 },
			{ x: -292.7157954832141, y: 196.40746738571252 },
			{ x: -294.54225216905894, y: 196.02310750204802 },
			{ x: -296.36511995051325, y: 195.63635913867967 },
			{ x: -298.1843766165078, y: 195.24722700801436 },
			{ x: -299.9999999999747, y: 194.8557158515042 },
			{ x: -301.81196797811594, y: 194.46183043958905 },
			{ x: -303.62025847267313, y: 194.06557557163845 },
			{ x: -305.4248494501978, y: 193.66695607589287 },
			{ x: -307.22571892231787, y: 193.2659768094051 },
			{ x: -309.02284494600764, y: 192.86264265798087 },
			{ x: -310.8162056238534, y: 192.4569585361196 },
			{ x: -312.605779104321, y: 192.04892938695434 },
			{ x: -314.3915435820229, y: 191.63856018219138 },
			{ x: -316.17347729798183, y: 191.22585592205013 },
			{ x: -317.9515585398985, y: 190.8108216352016 },
			{ x: -319.72576564241365, y: 190.39346237870765 },
			{ x: -321.4960769873736, y: 189.9737832379592 },
			{ x: -323.26247100409364, y: 189.551789326614 },
			{ x: -325.0249261696197, y: 189.12748578653483 },
			{ x: -326.78342100899215, y: 188.70087778772628 },
			{ x: -328.53793409550616, y: 188.27197052827225 },
			{ x: -330.2884440509734, y: 187.8407692342724 },
			{ x: -332.0349295459826, y: 187.4072791597783 },
			{ x: -333.77736930015897, y: 186.97150558672988 },
			{ x: -335.51574208242437, y: 186.5334538248904 },
			{ x: -337.25002671125486, y: 186.0931292117824 },
			{ x: -338.9802020549391, y: 185.6505371126225 },
			{ x: -340.70624703183734, y: 185.20568292025547 },
			{ x: -342.4281406106355, y: 184.75857205508953 },
			{ x: -344.1458618106044, y: 184.30920996502925 },
			{ x: -345.8593897018528, y: 183.85760212541004 },
			{ x: -347.56870340558413, y: 183.40375403893086 },
			{ x: -349.2737820943506, y: 182.9476712355874 },
			{ x: -350.9746049923062, y: 182.48935927260476 },
			{ x: -352.67115137546097, y: 182.0288237343694 },
			{ x: -354.36340057193246, y: 181.56607023236168 },
			{ x: -356.0513319621981, y: 181.10110440508697 },
			{ x: -357.7349249793469, y: 180.63393191800705 },
			{ x: -359.41415910932886, y: 180.16455846347137 },
			{ x: -361.0890138912065, y: 179.69298976064724 },
			{ x: -362.75946891740256, y: 179.2192315554505 },
			{ x: -364.4255038339496, y: 178.7432896204755 },
			{ x: -366.0870983407384, y: 178.2651697549243 },
			{ x: -367.7442321917636, y: 177.7848777845368 },
			{ x: -369.39688519537293, y: 177.3024195615189 },
			{ x: -371.04503721451033, y: 176.81780096447193 },
			{ x: -372.6886681669641, y: 176.33102789832054 },
			{ x: -374.3277580256095, y: 175.84210629424086 },
			{ x: -375.9622868186541, y: 175.3510421095884 },
			{ x: -377.5922346298802, y: 174.8578413278252 },
			{ x: -379.2175815988882, y: 174.36250995844725 },
			{ x: -380.83830792133824, y: 173.86505403691086 },
			{ x: -382.4543938491915, y: 173.3654796245595 },
			{ x: -384.0658196909509, y: 172.86379280854976 },
			{ x: -385.67256581190117, y: 172.35999970177713 },
			{ x: -387.2746126343479, y: 171.8541064428016 },
			{ x: -388.8719406378562, y: 171.3461191957729 },
			{ x: -390.46453035948826, y: 170.83604415035538 },
			{ x: -392.0523623940409, y: 170.32388752165244 },
			{ x: -393.635417394282, y: 169.809655550131 },
			{ x: -395.21367607118583, y: 169.29335450154537 },
			{ x: -396.78711919416867, y: 168.77499066686082 },
			{ x: -398.3557275913225, y: 168.25457036217716 },
			{ x: -399.919482149649, y: 167.73209992865154 },
			{ x: -401.47836381529237, y: 167.20758573242134 },
			{ x: -403.0323535937715, y: 166.68103416452644 },
			{ x: -404.58143255021145, y: 166.15245164083157 },
			{ x: -406.12558180957376, y: 165.62184460194803 },
			{ x: -407.66478255688725, y: 165.0892195131551 },
			{ x: -409.19901603747644, y: 164.55458286432128 },
			{ x: -410.72826355719053, y: 164.0179411698256 },
			{ x: -412.2525064826311, y: 163.47930096847765 },
			{ x: -413.77172624137916, y: 162.93866882343826 },
			{ x: -415.28590432222137, y: 162.39605132213967 },
			{ x: -416.7950222753756, y: 161.85145507620476 },
			{ x: -418.29906171271597, y: 161.30488672136693 },
			{ x: -419.79800430799645, y: 160.75635291738914 },
			{ x: -421.2918317970747, y: 160.20586034798262 },
			{ x: -422.780525978134, y: 159.6534157207257 },
			{ x: -424.2640687119057, y: 159.09902576698175 },
			{ x: -425.7424419218899, y: 158.54269724181736 },
			{ x: -427.2156275945752, y: 157.98443692392019 },
			{ x: -428.6836077796593, y: 157.4242516155159 },
			{ x: -430.146364590267, y: 156.86214814228575 },
			{ x: -431.603880203168, y: 156.2981333532832 },
			{ x: -433.0561368589946, y: 155.73221412085041 },
			{ x: -434.5031168624578, y: 155.16439734053466 },
			{ x: -435.94480258256283, y: 154.59468993100415 },
			{ x: -437.3811764528242, y: 154.023098833964 },
			{ x: -438.8122209714796, y: 153.4496310140713 },
			{ x: -440.2379187017031, y: 152.87429345885042 },
			{ x: -441.6582522718179, y: 152.29709317860792 },
			{ x: -443.07320437550754, y: 151.71803720634708 },
			{ x: -444.4827577720273, y: 151.1371325976822 },
			{ x: -445.88689528641385, y: 150.55438643075254 },
			{ x: -447.2855998096946, y: 149.96980580613632 },
			{ x: -448.6788542990964, y: 149.38339784676396 },
			{ x: -450.066641778253, y: 148.79516969783134 },
			{ x: -451.4489453374119, y: 148.20512852671285 },
			{ x: -452.8257481336405, y: 147.61328152287393 },
			{ x: -454.19703339103114, y: 147.01963589778362 },
			{ x: -455.56278440090574, y: 146.4241988848264 },
			{ x: -456.92298452201936, y: 145.82697773921444 },
			{ x: -458.2776171807626, y: 145.22797973789892 },
		],
	},
	{
		positionIndex: 1100,
		id: 'planet-2',
		positionsArray: [],
	},
	{
		positionIndex: 600,
		id: 'planet-3',
		positionsArray: [],
	},
	{
		positionIndex: 0,
		id: 'moon',
		positionsArray: [
			{ x: 32.5, y: 0 },
			{ x: 32.471491978212896, y: 0.41875653729199436 },
			{ x: 32.38601792560889, y: 0.8367784333231533 },
			{ x: 32.243727792720534, y: 1.253332335643039 },
			{ x: 32.04487120479141, y: 1.6676874671610205 },
			{ x: 31.789797023848685, y: 2.07911690817759 },
			{ x: 31.47895273668051, y: 2.486898871648549 },
			{ x: 31.112883669792186, y: 2.890317969444716 },
			{ x: 30.692232032718135, y: 3.28866646738583 },
			{ x: 30.217735791368174, y: 3.6812455268467783 },
			{ x: 29.69022737338453, y: 4.067366430758001 },
			{ x: 29.11063220778092, y: 4.446351791849274 },
			{ x: 28.479967101425565, y: 4.817536741017153 },
			{ x: 27.799338455216468, y: 5.1802700937313 },
			{ x: 27.069940323078235, y: 5.53391549243344 },
			{ x: 26.293052317185793, y: 5.877852522924732 },
			{ x: 25.470037363089798, y: 6.211477802783102 },
			{ x: 24.602339308682087, y: 6.534206039901052 },
			{ x: 23.691480391195878, y: 6.845471059286885 },
			{ x: 22.739058566684385, y: 7.144726796328031 },
			{ x: 21.7467447066629, y: 7.43144825477394 },
			{ x: 20.71627966683242, y: 7.705132427757891 },
			{ x: 19.64947123302719, y: 7.965299180241962 },
			{ x: 18.548190949744036, y: 8.211492091337039 },
			{ x: 17.4143708368174, y: 8.443279255020148 },
			{ x: 16.25000000000001, y: 8.660254037844386 },
			{ x: 15.05712114139552, y: 8.862035792312145 },
			{ x: 13.837826975864866, y: 9.048270524660195 },
			{ x: 12.594256559693351, y: 9.218631515885004 },
			{ x: 11.328591537958998, y: 9.372819894918914 },
			{ x: 10.043052317185792, y: 9.510565162951535 },
			{ x: 8.739894169996136, y: 9.631625667976582 },
			{ x: 7.42140327859631, y: 9.735789028731602 },
			{ x: 6.08989272403604, y: 9.822872507286887 },
			{ x: 4.7476984282783645, y: 9.892723329629884 },
			{ x: 3.397175056198723, y: 9.945218953682733 },
			{ x: 2.040691884702668, y: 9.980267284282716 },
			{ x: 0.6806286462090825, y: 9.997806834748456 },
			{ x: -0.680628646209129, y: 9.997806834748456 },
			{ x: -2.0406918847027145, y: 9.980267284282714 },
			{ x: -3.3971750561987695, y: 9.945218953682733 },
			{ x: -4.74769842827841, y: 9.892723329629883 },
			{ x: -6.089892724036085, y: 9.822872507286885 },
			{ x: -7.421403278596355, y: 9.735789028731599 },
			{ x: -8.73989416999618, y: 9.631625667976579 },
			{ x: -10.043052317185838, y: 9.510565162951531 },
			{ x: -11.328591537959042, y: 9.37281989491891 },
			{ x: -12.594256559693394, y: 9.218631515884999 },
			{ x: -13.837826975864916, y: 9.048270524660186 },
			{ x: -15.057121141395562, y: 8.862035792312138 },
			{ x: -16.250000000000057, y: 8.660254037844377 },
			{ x: -17.414370836817447, y: 8.44327925502014 },
			{ x: -18.548190949744086, y: 8.211492091337028 },
			{ x: -19.64947123302724, y: 7.965299180241949 },
			{ x: -20.71627966683247, y: 7.705132427757878 },
			{ x: -21.74674470666295, y: 7.431448254773927 },
			{ x: -22.73905856668443, y: 7.144726796328017 },
			{ x: -23.691480391195938, y: 6.845471059286866 },
			{ x: -24.602339308682144, y: 6.534206039901033 },
			{ x: -25.47003736308985, y: 6.211477802783081 },
			{ x: -26.29305231718585, y: 5.877852522924707 },
			{ x: -27.06994032307829, y: 5.533915492433414 },
			{ x: -27.799338455216517, y: 5.180270093731275 },
			{ x: -28.47996710142562, y: 4.817536741017125 },
			{ x: -29.110632207780966, y: 4.446351791849245 },
			{ x: -29.690227373384573, y: 4.067366430757973 },
			{ x: -30.217735791368213, y: 3.6812455268467477 },
			{ x: -30.692232032718174, y: 3.2886664673857986 },
			{ x: -31.11288366979222, y: 2.89031796944468 },
			{ x: -31.478952736680544, y: 2.486898871648511 },
			{ x: -31.78979702384871, y: 2.0791169081775553 },
			{ x: -32.044871204791434, y: 1.6676874671609805 },
			{ x: -32.24372779272055, y: 1.2533323356430035 },
			{ x: -32.386017925608904, y: 0.8367784333231134 },
			{ x: -32.4714919782129, y: 0.4187565372919678 },
			{ x: -32.5, y: 0 },
			{ x: -32.47149197821289, y: -0.4187565372920474 },
			{ x: -32.386017925608876, y: -0.8367784333232063 },
			{ x: -32.24372779272051, y: -1.253332335643083 },
			{ x: -32.044871204791384, y: -1.6676874671610733 },
			{ x: -31.78979702384865, y: -2.0791169081776433 },
			{ x: -31.47895273668047, y: -2.4868988716485982 },
			{ x: -31.11288366979214, y: -2.8903179694447645 },
			{ x: -30.692232032718078, y: -3.288666467385881 },
			{ x: -30.217735791368106, y: -3.681245526846829 },
			{ x: -29.69022737338446, y: -4.0673664307580495 },
			{ x: -29.11063220778084, y: -4.446351791849324 },
			{ x: -28.47996710142548, y: -4.817536741017202 },
			{ x: -27.799338455216375, y: -5.180270093731348 },
			{ x: -27.069940323078136, y: -5.533915492433486 },
			{ x: -26.293052317185676, y: -5.87785252292478 },
			{ x: -25.47003736308967, y: -6.211477802783152 },
			{ x: -24.60233930868195, y: -6.5342060399011 },
			{ x: -23.691480391195736, y: -6.845471059286933 },
			{ x: -22.73905856668423, y: -7.144726796328078 },
			{ x: -21.74674470666274, y: -7.431448254773985 },
			{ x: -20.716279666832254, y: -7.705132427757934 },
			{ x: -19.649471233027015, y: -7.965299180242002 },
			{ x: -18.54819094974386, y: -8.211492091337076 },
			{ x: -17.41437083681721, y: -8.443279255020185 },
			{ x: -16.24999999999981, y: -8.66025403784442 },
			{ x: -15.057121141395314, y: -8.862035792312177 },
			{ x: -13.837826975864663, y: -9.048270524660223 },
			{ x: -12.594256559693141, y: -9.218631515885033 },
			{ x: -11.328591537958786, y: -9.372819894918939 },
			{ x: -10.043052317185577, y: -9.510565162951556 },
			{ x: -8.739894169995889, y: -9.631625667976602 },
			{ x: -7.421403278596089, y: -9.735789028731618 },
			{ x: -6.089892724035824, y: -9.8228725072869 },
			{ x: -4.747698428278175, y: -9.892723329629892 },
			{ x: -3.397175056198562, y: -9.94521895368274 },
			{ x: -2.040691884702506, y: -9.98026728428272 },
			{ x: -0.6806286462089494, y: -9.997806834748456 },
			{ x: 0.6806286462092549, y: -9.997806834748454 },
			{ x: 2.0406918847028113, y: -9.980267284282714 },
			{ x: 3.397175056198866, y: -9.94521895368273 },
			{ x: 4.747698428278478, y: -9.892723329629879 },
			{ x: 6.089892724036153, y: -9.822872507286881 },
			{ x: 7.421403278596386, y: -9.735789028731597 },
			{ x: 8.73989416999621, y: -9.631625667976575 },
			{ x: 10.043052317185841, y: -9.510565162951531 },
			{ x: 11.328591537959044, y: -9.37281989491891 },
			{ x: 12.59425655969337, y: -9.218631515885003 },
			{ x: 13.837826975864887, y: -9.048270524660191 },
			{ x: 15.057121141395507, y: -8.862035792312145 },
			{ x: 16.250000000000004, y: -8.660254037844386 },
			{ x: 17.41437083681737, y: -8.443279255020155 },
			{ x: 18.54819094974401, y: -8.211492091337044 },
			{ x: 19.649471233027143, y: -7.965299180241972 },
			{ x: 20.71627966683238, y: -7.705132427757901 },
			{ x: 21.746744706662835, y: -7.431448254773958 },
			{ x: 22.73905856668432, y: -7.14472679632805 },
			{ x: 23.691480391195807, y: -6.8454710592869095 },
			{ x: 24.60233930868202, y: -6.534206039901078 },
			{ x: 25.470037363089716, y: -6.2114778027831345 },
			{ x: 26.29305231718572, y: -5.877852522924761 },
			{ x: 27.069940323078153, y: -5.5339154924334775 },
			{ x: 27.79933845521639, y: -5.180270093731342 },
			{ x: 28.479967101425483, y: -4.817536741017199 },
			{ x: 29.11063220778084, y: -4.446351791849324 },
			{ x: 29.69022737338445, y: -4.067366430758058 },
			{ x: 30.217735791368096, y: -3.681245526846837 },
			{ x: 30.69223203271806, y: -3.288666467385898 },
			{ x: 31.11288366979212, y: -2.8903179694447836 },
			{ x: 31.478952736680448, y: -2.4868988716486227 },
			{ x: 31.78979702384863, y: -2.07911690817767 },
			{ x: 32.04487120479136, y: -1.6676874671611033 },
			{ x: 32.24372779272049, y: -1.2533323356431274 },
			{ x: 32.38601792560887, y: -0.8367784333232462 },
			{ x: 32.47149197821288, y: -0.41875653729210044 },
		],
	},
];

const MILLIS_IN_A_SECOND = 1000;
const interval = MILLIS_IN_A_SECOND / 30;
const elements = [
	document.getElementById('planet-1'),
	document.getElementById('planet-2'),
	document.getElementById('planet-3'),
	document.getElementById('moon'),
];
const arrayLengths = [1799, 1799, 1799, 149];

function moveCircle(index, x, y) {
	elements[index].style.cssText +=
		'transform:translate(' + x + 'px,' + y + 'px);';
}

function hideMoon(index) {
	elements[index].style.cssText += 'z-index:-1;';
}

function showMoon(index) {
	elements[index].style.cssText += 'z-index:1;';
}

function resetOrIncrementIndex(positionIndex, arrayLength) {
	return positionIndex >= arrayLength ? 0 : ++positionIndex;
}

var stop = false;
var frameCount = 0;
var fps, fpsInterval, startTime, now, then, elapsed;

startAnimating(30);

function startAnimating(fps) {
	fpsInterval = 1000 / fps;
	then = Date.now();
	startTime = then;
	animate();
}

function animate() {
	if (stop) {
		return;
	}

	requestAnimationFrame(animate);

	now = Date.now();
	elapsed = now - then;

	if (elapsed > fpsInterval) {
		then = now - (elapsed % fpsInterval);
		positions.forEach(({ id, positionIndex, positionsArray }, index, array) => {
			let x;
			let y;

			if (id.includes('planet')) {
				x = positions[0].positionsArray[positionIndex].x;
				y = positions[0].positionsArray[positionIndex].y;
			} else {
				x = positionsArray[positionIndex].x;
				y = positionsArray[positionIndex].y;

				if (positionIndex === 0 && id === 'moon') {
					showMoon(index);
				} else if (positionIndex === 80 && id == 'moon') {
					hideMoon(index);
				}
			}

			moveCircle(index, x, y);

			array[index].positionIndex = resetOrIncrementIndex(
				positionIndex,
				arrayLengths[index]
			);
		});
	}
}
